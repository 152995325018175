<template>
  <section class="rt-container home__products sp-t-5 td-sp-t-3-1 td-sp-b-2 md-sp-t-none md-sp-b-1">
    <div class="rt-col">

      <div class="row">
        <h1 :class="
        [
          'home__products__header',
          'rt-col-8',
          'rt-col-td-6',
          'font-h2',
          data.description ? 'sp-b-1' : 'sp-b-3',
          'td-sp-b-1-1',
          'md-sp-v-1'
        ]"
        >{{ data.title }}</h1>
      </div>

      <div v-if="data.description" class="row">
        <p class="trust__description font-t-l rt-col-9 rt-col-td-6 sp-b-2 td-sp-b-1-1" v-html="data.description"></p>
      </div>

      <!-- Desktop -->
      <div class="home__products_desktop">
        <!-- Верхние 3 плитки -->
        <div class="home__products__cards d-flex" style="border-bottom: 2px solid #EEEFF0;">
          <NuxtLink
            :to="card.link"
            v-for="(card, idx) of cards.slice(0, 3)" :key="idx"
            class="home__products__cards-card home__products__cards-card__top"
          >
            <div class="card__content sp-v-1">
              <div class="card__content__text" :style="{ backgroundImage: 'url(' + card.background + ')' }">
                <img class="sp-b-2 sp-t-1-3 sp-h-1" :src="card.icon" alt="" >
                <h4 class="title font-h4 sp-b-2 sp-h-1">{{ card.header }}</h4>
                <p class="desc font-t-l sp-b-1-3 sp-h-1">{{ card.description }}</p>
              </div>
            </div>
          </NuxtLink>
        </div>

        <!-- Нижние 4 плитки -->
        <div class="home__products__cards d-flex">
          <NuxtLink
            :to="card.link"
            v-for="(card, idx) of cards.slice(3)" :key="idx"
            class="home__products__cards-card home__products__cards-card__bottom"
          >
            <div class="card__content sp-v-1">
              <div class="card__content__text" :style="{ backgroundImage: 'url(' + card.background + ')' }">
                <img class="sp-b-4 sp-t-3 sp-h-1" :src="card.icon" alt="" >
                <h4 class="title font-h4 sp-b-2 sp-h-1">{{ card.header }}</h4>
                <p class="desc font-t-l sp-b-3 sp-h-1">{{ card.description }}</p>
              </div>
            </div>
          </NuxtLink>
        </div>
      </div>

      <!-- Tablet / Mobile -->
      <div class="home__products_tablet row">
        <NuxtLink
          :to="card.link"
          v-for="(card, idx) of cards.slice(0, 6)" :key="idx"
          class="home__products__cards-td rt-col-td-3 sp-b-1 md-sp-b-0-3"
        >
          <div class="home__products__cards-td__card">
            <img class="sp-v-1 sp-h-1" :src="card.icon" alt="" >
            <h4 class="title font-h-s sp-b-1 sp-h-1 td-sp-b-0-2">{{ card.header }}</h4>
            <p class="desc font-t-xs sp-b-1 sp-h-1">{{ card.description }}</p>
          </div>
        </NuxtLink>
        <NuxtLink
          :to="cards[6].link"
          class="home__products__cards-td rt-col-td-6 sp-b-1 md-sp-t-0-2"
        >
          <div class="home__products__cards-td__card__more">
            <p class="font-h4">{{ cards[6].header }}</p>
            <span class="td-sp-l-0-3" v-html="icons.arrowRight"></span>
          </div>
        </NuxtLink>
      </div>

    </div>

  </section>
</template>

<script setup>
import icons from '~/assets/icons'

const props = defineProps({
  data: Object
})

const config = useRuntimeConfig()

const cards = handleStrapiArray(props.data.products).map(card => {
  return {
    header: card.name,
    description: card.type,
    link: card.link,
    icon: config.public.strapiUrl + card.icon.data.attributes.image.data.attributes.url,
    background: config.public.strapiUrl + card.background.data.attributes.url
  }
})

</script>

<style lang="stylus">
.home__products
  position relative
  background-color #fff
  @media (max-width: tablet-upper-limit)
    background-color main-color003-no-opacity
    background-image url(/assets/Rectangle_4924.png)
    background-position -1100px -400px
    background-repeat no-repeat
  @media (max-width: mobile-upper-limit)
    background-position -1220px -440px
    
.home__products_desktop
  @media (max-width: tablet-upper-limit)
    display none

.home__products__header
  color b2c-dark-blue

.home__products__cards
  margin 0

  &-card
    position relative
    padding 0
    width 100%
    flex-wrap nowrap!important

    .card__content

      &__text
        border-radius 20px
        background-repeat no-repeat
        background-origin content-box
        background-size cover
        .title
          transition color .35s
        .desc
          transition color .35s
      
    &:not(:hover)
      .card__content__text
        background-image none!important

    @media (hover: hover)
      &:hover
        .card__content
          .title
            color white
          .desc
            color b2c-white07
    

  &-card__top
    .title
      color b2c-dark-blue
    
    &:nth-child(1)
      padding-right 20px
      border-right 2px solid main-color007-no-opacity
      .card__content
        padding-left 0
    &:nth-child(2)
      border-right 2px solid main-color007-no-opacity
      padding 0 20px
    &:nth-child(3)
      padding-right 20px
      padding-left 20px
      .card__content
        padding-right 0

  &-card__bottom
    border-right 2px solid main-color007-no-opacity
    padding 0 20px

    &:first-child
      padding-left 0
    &:last-child
      border-right none
      padding-right 20px
    .title
      color b2c-dark-blue
      min-height 96px


.home__products_tablet
  display none

  .title
    @media (max-width: mobile-upper-limit)
      min-height 40px
    @media (max-width: 410px)
      min-height 56px
  
  @media (max-width: tablet-upper-limit)
    display flex

.home__products__cards-td

  @media (max-width: mobile-upper-limit)
    &:nth-child(odd)
      padding-right 6px
    &:nth-child(even)
      padding-left 6px

  &__card
    border-radius 16px
    background-color #fff

    &__more
      background-color #fff
      border-radius 12px
      display flex
      justify-content center
      align-items center
      height 48px
      color b2c-dark-blue

      span
        display flex
        justify-content center
        align-items center
        svg
          path
            fill #273A64

.desc
  color main-color05-no-opacity
</style>
