<template>
  <div class="banner color-white">
<!--    <img :src="imgUrl" alt="" class="banner__image">-->
    <video :src="videoUrl" class="banner__video" autoplay loop playsinline muted ref="video" style="width:100%"/>
    <div class="rt-container">
      <div class="rt-col">
        <div class="row">
          <div class="rt-col-8 rt-col-td-5 rt-col-md-3">
            <div class="banner__content td-sp-b-5 md-sp-b-2">
              <h2 class="banner__title">{{data.title}}</h2>
              <p class="banner__subtitle sp-t-1-3 td-sp-t-0-3" v-html="data.description" v-if="data.description"/>
              <div class="banner__scroll-button" @click="scrollBottom">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="30" cy="30" r="30" fill="white" class="button-bg"/>
                  <path d="M31.2123 40.4866V15H28.3907V40.1919L19.179 28.7007L17 30.5403L28.798 45.2587C29.0637 45.5903 29.4593 45.7838 29.8782 45.7865H29.8877C30.3029 45.7865 30.6975 45.5987 30.9649 45.2735L43.0753 30.5672L40.9202 28.6986L31.2123 40.4866Z" fill="white" fill-opacity="0.7"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M30 57C44.9117 57 57 44.9117 57 30C57 15.0883 44.9117 3 30 3C15.0883 3 3 15.0883 3 30C3 44.9117 15.0883 57 30 57ZM30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z" fill="white" fill-opacity="0.7"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import smoothScroll from "~/utils/smoothScroll"
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      config: {},
      content: null,
      videoUrl: '',
      imgUrl: ''
    }
  },
  mounted() {
    this.config = useRuntimeConfig()
    this.content = toRaw(this.data)
    this.videoUrl = this.config.public?.strapiUrl + this.data.video.data.attributes.url
    this.imgUrl = this.config.public.strapiUrl + this.data.background.data.attributes.url
    this.$refs.video.play()

    window.addEventListener('resize', () => {
      let zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
      const scrollDownButton = document.querySelector('.banner__scroll-button')
      if (scrollDownButton) {
        if(zoom <= 100) scrollDownButton.style.bottom = '-180px'
        if(zoom > 100) scrollDownButton.style.bottom = '-120px'
        if(zoom > 120) scrollDownButton.style.bottom = '-100px'
        if(zoom > 140) scrollDownButton.style.bottom = '-75px'
      }
    })
  
  },
  methods: {
    scrollBottom() {
      const targetPosition = pageYOffset + document.querySelector('.achievements').getBoundingClientRect().top - document.querySelector('.header').getBoundingClientRect().height
      smoothScroll(pageYOffset, targetPosition)
    }
  }
}
</script>

<style lang="stylus">
.banner
  height calc(100vh - 96px)
  position relative
  display flex
  align-items center
  justify-content center
  @media (max-width tablet-upper-limit)
    height 440px
    align-items flex-end
  @media (max-width mobile-upper-limit)
    height 360px
  &__image
    width 100%
    height 100%
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    object-fit cover
    z-index -2
  &__video
    width 100%
    height 100%
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    object-fit cover
    &:after
      position absolute
      content ''
      top 0
      left 0
      right 0
      bottom 0
      background linear-gradient(180deg, rgba(40, 95, 236, 0.36) 0%, rgba(7, 7, 2, 0.234) 100%)
  &__title
    font-weight 700
    font-size 80px
    line-height 95px
    @media (max-width desktop-upper-limit)
      font-size 64px
      line-height 80px
    @media (max-width tablet-upper-limit)
      font-size 48px
      line-height 64px
    @media (max-width mobile-upper-limit)
      font-size 28px
      line-height 32px
  &__subtitle
    font-size 36px
    line-height 44px
    white-space: pre-wrap;
    @media (max-width tablet-upper-limit)
      font-size 32px
      line-height 40px
    @media (max-width mobile-upper-limit)
      font-size 18px
      line-height 28px
  &__content
    position relative
  &__scroll-button
    position absolute
    left 0
    bottom -180px
    width 60px
    height 60px
    cursor pointer
    pointer-events auto
    .button-bg
      opacity 0
      transition opacity.2s linear
    @media (hover: hover)
      &:hover
        .button-bg
          opacity .07
    @media (max-width tablet-upper-limit)
      display none
  .rt-container
    pointer-events none
</style>