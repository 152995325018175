<template>
  <div class="form-steps">
    <p class="font-t-m sp-b-1 form-steps__title">Заполните форму и наш менеджер свяжется с вами в&nbsp;ближайшее время</p>
    <div class="form-steps__wrapper">
      <div class="form-steps__item sp-t-1" v-for="(item, index) in stepsCount"
           :class="{
             'form-steps__item--hidden': isMobile && (index + 1) !== step,
             'form-steps__item--active' : item <= step,
           }">
        <div class="form-steps__item-icon sp-r-0-3">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="item > step">
            <circle opacity="0.1" cx="12" cy="12" r="10" fill="#868B95"/>
            <path d="M7 12.75H17V11.25H7V12.75Z" fill="#868B95"/>
          </svg>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM11.061 15.5704L17.561 9.17681L16.439 8.03613L10.5047 13.8733L8.06571 11.4343L6.93434 12.5657L9.93434 15.5657C10.2449 15.8763 10.7479 15.8784 11.061 15.5704Z" fill="#101828"/>
          </svg>
        </div>
        <div class="form-steps__item-content">
          <p class="form-steps__item-title">Шаг {{ item }}</p>
          <p class="form-steps__item-description color-main05 font-t-l">{{ stepsDescription[item - 1]}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: 1
    },
    stepsCount: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      stepsDescription: ['Выбор причины обращения', 'Введите данные', 'Перейти на HR-сайт'],
      isMobile: false
    }
  },
  mounted() {
    if(process.client) {
      this.isMobile = window.innerWidth < 768
    }
  },
}
</script>

<style lang="stylus">
.form-steps
  &__title
    border-bottom 2px solid main-color003
  &__item
    display flex
    align-items flex-start
    justify-content flex-start
    .form-steps__item-title
      opacity .5
      font-size 16px
      line-height 24px
    &--active
      .form-steps__item-title
        opacity 1
    &--hidden
      @media (max-width mobile-upper-limit)
        display none
</style>