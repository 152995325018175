<template>
  <div class="form__choice">
    <p class="font-h-l sp-b-1-2">Спасибо. Ваша заявка принята!</p>
    <p class="font-t-m sp-b-2">Больше информации о вакансиях Вы можете получить на нашем HR-сайте</p>
    <a :href="link" target="_blank" class="form__button" @click="sendAnalytics">{{ linkTitle }}</a>
  </div>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      default: ''
    },
    linkTitle: {
      type: String,
      default: ''
    }
  },
  methods: {
    sendAnalytics() {
      if(process.client) {
        if(!window.dataLayer) window.dataLayer = []
        const analytics = {
          "event":"b2b",
          "type": "button",
          "value": "order_succes_step_3",
          "label": "Хочу работать в Ростелеком Контакт-центре",
          "content": "form",
          "num": "NA",
          "url": this.$route.fullPath
        }
        window.dataLayer.push(analytics)
        ym(64709935, 'reachGoal', 'order_succes_step_3', {'events': analytics})
      }
    }
  }
}
</script>