<template>
  <section class="suitable sp-v-5 td-sp-v-3-1 md-sp-v-2">
    <div class="rt-container">
      <div class="rt-col">
        <div class="row">
          <div class="rt-col-9 rt-col-td-6">
            <div class="suitable__header sp-b-2 td-sp-b-1-1 md-sp-b-1">
              <h2 class="suitable__title font-h2 sp-b-1 td-sp-b-1-1 md-sp-b-1 color-dark-blue">{{ header }}</h2>
              <p class="suitable__description font-t-l color-main085-no-opacity" v-html="description"></p>
            </div>
          </div>
          <div class="rt-col-4 rt-col-td-3 rt-col-md-3">
            <div class="suitable__image d-flex">
              <img :src="image" alt="image" class="suitable__image-body"/>
            </div>
          </div>
          <div class="rt-col-8 rt-col-td-3 rt-col-md-3 d-flex">
            <div class="suitable__cards row">
              <NuxtLink v-for="card of cards" :key="card.id" class="rt-col suitable__cards-item sp-v-0-3" :to="card.link">
                <div class="suitable__cards-icon sp-r-0-3">
                  <img :src="config.public.strapiUrl + card.icon.data.attributes.image.data.attributes.url" alt="">
                </div>
                <div class="suitable__cards-content">
                  <p class="suitable__cards-title font-h-l color-main085-no-opacity">{{ card.header }}</p>
                  <p class="suitable__cards-desc sp-t-0-2 font-t-l td-d-none color-main05-no-opacity">{{ card.description }}</p>
                </div>
              </NuxtLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
const props = defineProps({
  data: Object
})

const config = useRuntimeConfig()

const header = props.data.header
const description = props.data.description
const image = config.public.strapiUrl + props.data.image?.data?.attributes?.url
const cards = handleStrapiArray(props.data.iconcards)
</script>

<style lang="stylus" scoped>
.suitable
  //border-bottom 2px solid main-color007-no-opacity
  &__image
    height 396px
    @media (max-width tablet-upper-limit)
      height 246px
    @media (max-width mobile-upper-limit)
      height 200px
      margin-bottom 8px
  &__image-body
    object-fit cover
    width 100%
    height 100%
    border-radius 20px
    @media (max-width mobile-upper-limit)
      border-radius 16px
  &__description
    white-space: pre-wrap;
    @media (max-width mobile-upper-limit)
      font-size 14px
      line-height 20px
  &__cards
    display flex
    flex-wrap wrap
    align-content center
    justify-content flex-start
    height 100%
    &-title,
    &-desc
      transition color .2s linear
    &-icon
      transition opacity .2s linear
      @media (max-width tablet-upper-limit)
        display flex
    &-item
      flex 0 0 50%
      position relative
      display flex
      cursor pointer
      @media(hover: hover)
        &:hover
          .suitable__cards-title
            color main-color05-no-opacity
          .suitable__cards-desc
            color main-color03-no-opacity
          .suitable__cards-icon
            opacity .5
      @media (max-width tablet-upper-limit)
        flex-basis 100%
        align-items center
      &:before,
      &:after
        position absolute
        content ''
      &:before
        border-bottom 2px solid main-color007-no-opacity
        left 0
        right 0
        bottom 0
      &:nth-child(odd)
        &:before
          left 10px
          @media (max-width tablet-upper-limit)
            right 10px
      &:nth-child(even)
        &:before
          right 10px
          @media (max-width tablet-upper-limit)
            left 10px
      &:nth-child(odd)
        &:after
          position absolute
          content ''
          width 2px
          height 100%
          top 0
          right 10px
          bottom 0
          background-color main-color007-no-opacity
          @media (max-width tablet-upper-limit)
            display none
      &:last-of-type
        &:before
          border none
      &:nth-last-of-type(2):not(:nth-child(even))
        @media (min-width tablet-upper-limit)
          &:before
            border none
</style>
