<template>
  <NuxtLink v-for="card of items" :key="card.id" :to="card.link" class="tile__item" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
    <img v-if="card.image.data" :src="config.public.strapiUrl + card.image.data.attributes.url" class="tile__image"/>
    <div v-else class="tile__image__empty"></div>
    <span class="tile__text color-white" v-html="card.title"></span>
  </NuxtLink>
</template>

<script>
export default {
  props: {
    tile: Object
  },
  data () {
    return {
      items: [],
      config: {}
    }
  },
  mounted(){
    this.config = useRuntimeConfig()
    this.items = handleStrapiArray(this.tile.cards).map(item => {
      return item
    })
  },
  methods: {
    mouseEnter() {
      this.$emit('hover', true)
    },
    mouseLeave() {
      this.$emit('hover', false)
    }
  }
}
</script>

<style lang="stylus">
.tile
  display flex
  flex-direction row
  flex-wrap wrap
  align-items flex-start
  justify-content space-between
  padding 0 26px
  height 553px
  box-sizing border-box
  @media (max-width tablet-upper-limit)
    padding 0 10px
    height 300px
  &__block
    display flex
  &--type
    &-z4_a
      flex 0 0 704px
      align-items flex-end
      @media (max-width tablet-upper-limit)
        flex-basis 400px
      .tile__item
        &:nth-child(1)
          width 200px
          height 200px
          margin-bottom 52px
          border-radius 15px
          font-size 18px
          line-height 24px
          padding 24px 52px 24px 16px
          @media (max-width tablet-upper-limit)
            width 120px
            height 120px
            margin-bottom 20px
            padding 16px 12px
        &:nth-child(2)
          width 400px
          height 200px
          margin-bottom 52px
          font-size 24px
          line-height 32px
          padding 36px 100px 24px 32px
          @media (max-width tablet-upper-limit)
            width 240px
            height 120px
            margin-bottom 20px
            padding 16px 20px
        &:nth-child(3)
          width 400px
          height 300px
          font-size 24px
          line-height 32px
          padding 36px 100px 24px 32px
          @media (max-width tablet-upper-limit)
            width 240px
            height 160px
            padding 16px 20px
        &:nth-child(4)
          width 200px
          height 200px
          border-radius 15px
          font-size 18px
          line-height 24px
          padding 24px 52px 24px 16px
          @media (max-width tablet-upper-limit)
            width 120px
            height 120px
            padding 16px 12px
    &-z2_large
      flex 0 0 443px
      flex-direction column
      font-size 24px
      line-height 32px
      @media (max-width tablet-upper-limit)
        flex-basis 260px
      .tile__item
        &:nth-child(1)
          width 400px
          height 300px
          padding 36px 100px 24px 32px
          @media (max-width tablet-upper-limit)
            width 240px
            height 160px
            padding 16px 12px
        &:nth-child(2)
          width 400px
          height 200px
          padding 36px 100px 24px 32px
          @media (max-width tablet-upper-limit)
            width 240px
            height 120px
            padding 16px 20px
    &-z2_small
      flex 0 0 228px
      flex-direction column
      font-size 18px
      line-height 24px
      @media (max-width tablet-upper-limit)
        flex-basis 140px
      .tile__item
        &:nth-child(1)
          width 200px
          height 200px
          padding 24px 52px 24px 16px
          @media (max-width tablet-upper-limit)
            width 120px
            height 120px
            padding 16px 12px
        &:nth-child(2)
          width 200px
          height 200px
          padding 24px 52px 24px 16px
          @media (max-width tablet-upper-limit)
            width 120px
            height 120px
            padding 16px 12px
    &-z4_b
      flex 0 0 704px
      @media (max-width tablet-upper-limit)
        flex-basis 400px
      .tile__item
        &:nth-child(1)
          width 400px
          height 300px
          margin-bottom 52px
          font-size 24px
          line-height 32px
          padding 36px 100px 24px 32px
          @media (max-width tablet-upper-limit)
            margin-bottom 20px
            width 240px
            height 160px
            padding 16px 20px
        &:nth-child(2)
          width 200px
          height 200px
          margin-bottom 52px
          border-radius 15px
          font-size 18px
          line-height 24px
          padding 24px 52px 24px 16px
          @media (max-width tablet-upper-limit)
            margin-bottom 20px
            width 120px
            height 120px
            padding 16px 12px
        &:nth-child(3)
          width 400px
          height 200px
          font-size 24px
          line-height 32px
          padding 36px 100px 24px 32px
          @media (max-width tablet-upper-limit)
            width 240px
            height 120px
            padding 16px 20px
        &:nth-child(4)
          width 200px
          height 200px
          border-radius 15px
          font-size 18px
          line-height 24px
          padding 24px 52px 24px 16px
          @media (max-width tablet-upper-limit)
            width 120px
            height 160px
            margin-top -40px
            padding 16px 12px
    &-z3_a
      flex 0 0 497px
      @media (max-width tablet-upper-limit)
        flex-basis 280px
      .tile__item
        &:nth-child(1)
          width 200px
          height 300px
          font-size 18px
          line-height 24px
          padding 24px 52px 24px 16px
          @media (max-width tablet-upper-limit)
            width 120px
            height 160px
            padding 16px 12px
        &:nth-child(2)
          width 200px
          height 300px
          font-size 18px
          line-height 24px
          padding 24px 52px 24px 16px
          @media (max-width tablet-upper-limit)
            width 120px
            height 160px
            padding 16px 12px
        &:nth-child(3)
          width 452px
          height 200px
          margin-top 52px
          font-size 24px
          line-height 32px
          padding 36px 100px 24px 32px
          @media (max-width tablet-upper-limit)
            margin-top 20px
            width 260px
            height 120px
            padding 16px 20px
  &__image
    position absolute
    width 100%
    height 100%
    object-fit cover
    object-position center
    top 0
    left 0
    right 0
    bottom 0
    transition transform 1s
    border-radius 20px
    &_empty
      position absolute
      width 100%
      height 100%
      object-fit cover
      object-position center
      top 0
      left 0
      right 0
      bottom 0
      transition transform 1s
      border-radius 20px
      background-color #f0f0f0
  &__item
    flex-grow 0
    flex-shrink 0
    display flex
    position relative
    border-radius 20px
    overflow hidden
    align-items flex-end
    cursor pointer
    pointer-events auto
    z-index 0
    -webkit-transform: translateZ(0)
    @media (max-width tablet-upper-limit)
      font-size 12px!important
      line-height 16px!important
      border-radius 16px
    &:after
      position absolute
      content ''
      width 100%
      height 100%
      top 0
      left 0
      right 0
      bottom 0
      background linear-gradient(357.96deg, #273A64 -34.29%, #273A64 -16.92%, rgba(39, 58, 100, 0) 86.38%)
      z-index 2
      transition opacity 1s linear
    @media(hover: hover)
      &:hover
        .tile__image
          transform scale(1.2)
        &:after
          opacity .5
  &__text
    position relative
    z-index 3
    font-weight 700
    @media (max-width tablet-upper-limit)
      font-weight 500
</style>