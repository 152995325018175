<template>
  <div class="about__docs rt-container sp-b-5">
    <div class="rt-col">
      <h2 class="title font-h2 sp-b-2 md-sp-b-1">{{ title }}</h2>
      <div class="about__docs__tabs">
        <Tabs name="documentsTabs">
          <template v-slot:navigation>
            <TabsNavItem v-for="(tab, index) of tabs" :key="index" :name="`docktab_${index}`" parent-name="documentsTabs">
              <slot>{{ tab.frontName }}</slot>
            </TabsNavItem>
          </template>
          <template v-slot:content>
            <TabsContentItem v-for="(tab, index) of tabs" :key="index" :name="`docktab_${index}`" parent-name="documentsTabs">
              <div
                v-for="(file, idx) of tab.files" :key="idx"
                @click="downloadFile(file)"
                class="about__docs__file sp-b-1"
              >
                <div class="about__docs__file__icon">
                  <span v-html="icons.download" class="sp-r-0-3"></span>
                </div>
                <div class="about__docs__file__name">
                  {{ file.name }}
                </div>
              </div>
            </TabsContentItem>
          </template>
        </Tabs>
      </div>
    </div>
  </div>
</template>

<script setup>
import icons from '~/assets/icons'

const config = useRuntimeConfig()

const props = defineProps({
  data: Object
})

const title = props.data.title
const tabs = props.data.filetabs.data.map(tab => {
  return {
    frontName: tab.attributes.frontname,
    files: tab.attributes.files.map(file => {
      return {
        name: file.filename,
        file: config.public.strapiUrl + file.file.data.attributes.url,
        mime: file.file.data.attributes.mime
      }
    })
  }
})

const downloadFile = async (file) => {
  let fileData = await fetch(file.file)
  fileData = await fileData.blob()
  const url = URL.createObjectURL(new Blob([fileData], { type: file.mime }))
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.href = url
  a.download = file.name
  a.click()
}

</script>

<style lang="stylus">
.title
  color b2c-dark-blue

.about__docs__file
  display flex
  justify-content flex-start
  align-items center
  cursor pointer

  @media (hover: hover)
      &:hover
        & *
          color #868B95
          fill #868B95

  &:last-child
    padding 0

  &__name
    font-size 16px
    line-height 24px
    font-weight 500
    color b2c-dark-blue

</style>