<template>
  <div class="news rt-container">
    <div class="rt-col sp-v-5 td-sp-v-3 md-sp-v-2">
      <div class="row news__header sp-b-2 td-sp-b-0-1 md-sp-b-0-2">
        <h2 class="rt-col-8 rt-col-md-3 font-h2">
          {{ title }}
        </h2>
        <div class="news__more rt-col-4 td-d-none">
          <div class="news__more-button">
            <NuxtLink :to="link_more_url">
              <div class="news__more-caption" v-html="link_more_caption"></div>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1827 12.749L12.9711 16.9388L14.029 18.0022L19.5289 12.5307C19.6704 12.39 19.75 12.1986 19.75 11.999C19.75 11.7994 19.6704 11.6081 19.5289 11.4673L14.0289 5.99585L12.9711 7.05926L17.1826 11.249L4 11.249L4 12.749L17.1827 12.749Z" fill="#273A64"/>
              </svg>
            </NuxtLink>
          </div>
        </div>
      </div>
      <div class="news__cards row">
        <div v-for="card of news" :key="card.id" class="news__cards__wrapper sp-t-1 md-sp-t-0-3">
          <NewsCard :data="card"/>
        </div>
      </div>
      <div class="news__more-mobile td-d-flex d-none td-sp-t-1-1 md-sp-t-1">
        <div class="news__more-button">
          <NuxtLink :to="link_more_url">
            <div class="news__more-caption" v-html="link_more_caption"></div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1827 12.749L12.9711 16.9388L14.029 18.0022L19.5289 12.5307C19.6704 12.39 19.75 12.1986 19.75 11.999C19.75 11.7994 19.6704 11.6081 19.5289 11.4673L14.0289 5.99585L12.9711 7.05926L17.1826 11.249L4 11.249L4 12.749L17.1827 12.749Z" fill="#273A64"/>
            </svg>
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { lastNewsQuery } from '~/graphql/newsQuery'
  import NewsCard from '../NewsCard.vue'
  const props = defineProps({
    data: Object
  })
  const { title, link_more_caption, link_more_url } = props.data
  // закрепленные новости из компонента новостей для главной
  let news = handleStrapiArray(props.data.news) || []
  if (news.length < 3) {
    // незакрепленные новости из общего списка новостей
    const params = {
      count: 3 - news.length,
      exclude: news.map(newsItem => newsItem.slug),
      now: new Date().toISOString().replace(/T.*/, '')
    }
    const { data } = await useAsyncQuery(lastNewsQuery, params)
    news = news.concat(handleStrapiArray(toRaw(data.value.posts)))
    news.forEach(item => {
      item.link = '/news/' + item.slug
    })
  }

</script>

<style lang="stylus" scoped>
  .news
    &__header
      color #273A64
    &__cards
      display: flex
      flex-direction: row
      flex-wrap: wrap
      &__wrapper
        width 33.333333333%
        padding-left 10px
        padding-right 10px
        @media (max-width: tablet-upper-limit)
          width 50%
        @media (max-width: mobile-upper-limit)
          width 100%
    &__more
      &-button
        background-color #F8F8F8
        padding 12px 24px 12px 24px
        border-radius 8px
        width fit-content
        font-style normal
        font-weight 500
        font-size 16px
        line-height 24px
        color #273A64
        margin-left auto
        transition background-color 0.5s linear
        &:hover
          background-color #eeeff0
        svg
          vertical-align top
        a
          color #273A64
      &-caption
        padding-right 12px
        display inline-block
      &-mobile
        .news__more-button
          width 100%
        a
          display block
          margin auto
          width fit-content
        
</style>