<template>
  <NuxtLink :to="card.link" class="popular-pages__card" :style="{ backgroundImage: 'url(' + backgroundLink + ')' }">
    <link rel="prefetch" as="image" :href="backgroundLink" />
    <i v-html="icons.triangleRight" />
    <span class="popular-pages__card__title sp-l-0-4 font font-h4" v-html="card.title"></span>
  </NuxtLink>
</template>

<script setup>
  import icons from '~/assets/icons'

  const props = defineProps({
    card: Object
  })

  const config = useRuntimeConfig()
  const backgroundLink = config.public.strapiUrl + props.card.background.data.attributes.url
</script>

<style lang="stylus">
  @media (min-width laptop-lower-limit)
    .popular-pages__card:not(:hover)
      background #fff !important
      color #273A64
    .popular-pages__card:hover
      color #fff
      i svg path
        transition all .5s ease-out 
        fill #fff
        fill-opacity 1
</style>