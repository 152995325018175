import axios from 'axios'

const dadata = {
    company: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party',
}

const order = {
    email: `/api/fos/email`,
    service: `/api/fos/crm`
}
export function getCompany(queryString) {
    return axios.request({
        url: dadata.company,
        method: 'post',
        data: {
            'query': queryString
        },
        headers: {
            'Authorization': 'Token bcb985431cdfd17ac724a39a5232f18f5d456374',
            'Content-Type': 'application/json',
        }
    }).catch(error => {
        console.log(error)
    })
}

export function sendOrder(data) {
    const runtimeConfig = useRuntimeConfig()
    const formData = new FormData()
    for(let key in data) {
        formData.append(key, data[key])
    }
    return axios.request({
        url: runtimeConfig.public.strapiUrl + order.service + document.location.search,
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }).catch(error => {
        console.log(error)
    })
}

export function sendEmail(data) {
    const runtimeConfig = useRuntimeConfig()
    const formData = new FormData()
    for(let key in data) {
        if(key == 'file') {
            data.file?.forEach(item => {
                formData.append('file', item[0], item[0].name)
            })
        } else {
            formData.append(key, data[key])
        }
    }
    return axios.request({
        url: runtimeConfig.public.strapiUrl + order.email + document.location.search,
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }).catch(error => {
        console.log(error)
    })
}


