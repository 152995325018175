<template>
  <div class="advantage-card-wrapper sp-b-1 td-sp-b-0-3">
    <div class="advantage-card sp-h-1 sp-v-2 td-sp-v-1 ">
      <div class="advantage-card-icon">
        <img :src="cardIcon" alt="">
      </div>
      <div class="advantage-card-info sp-l-1 td-sp-l-0-3">
        <h3 class="advantage-card-info-header font-h4">{{ cardHeader }}</h3>
        <div class="advantage-card-info-desc">{{ cardDescription }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    card: Object
  },

  setup(props) {
    const cardData = toRaw(props.card)

    const config = useRuntimeConfig()

    const cardIcon = config.public.strapiUrl + cardData.icon.data.attributes.image.data.attributes.url
    const cardHeader = cardData.header
    const cardDescription = cardData.description
    return {
      cardIcon,
      cardHeader,
      cardDescription
    }
  },
}
</script>

<style lang="stylus">

.advantage-card
  height 100%
  border 2px solid main-color007-no-opacity
  border-radius 20px
  display flex

  &-icon
    img
      @media (max-width: tablet-upper-limit)
        width 40px
        height 40px

  &-info
    &-header
      margin 0
      padding 0 0 12px 0
      color b2c-dark-blue
    &-desc
      font-size 16px
      color main-color05-no-opacity

</style>
