<template>
  <section class="rt-container mmcontacts sp-t-5 sp-b-5 td-sp-t-3 td-sp-b-3 md-sp-b-2">
    <div class="rt-col">
      <div class="row">
        <div class="rt-col-8 rt-col-md-3">
          <h2 class="font-h2 sp-b-2 td-sp-b-1 color-dark-blue">{{ title }}</h2>
        </div>
      </div>
      <div class="row mmcontacts__content">
        <div class="rt-col-5 rt-col-md-3 md-sp-b-1 mmcontacts__image">
          <img :src="image" alt="image">
        </div>
        <div class="rt-col-7 rt-col-md-3">
          <div class="mmcontacts__text sp-l-2 td-sp-l-none">
            <p class="mmcontacts__text__description font-t-l sp-b-1-3 td-sp-b-1" v-html="content.description"></p>

            <div class="mmcontacts__text__cards">
              <div v-for="(card, idx) of cards" :key="idx" class="mmcontacts__text__cards__card">
                <div class="card__icon">
                  <img :src="card.icon" />
                </div>
                <div class="card__text sp-l-0-3">
                  <h4 class="font-h-l sp-b-0-2 td-sp-b-1">{{ card.header }}</h4>
                  <p class="font-t-l sp-b-1 td-d-none">{{ card.description }}</p>
                </div>
              </div>
            </div>

            <div class="mmcontacts__text__contacts font-t-m sp-b-1-3 td-sp-b-1">
              <a :href="`tel:${content.phone}`" class="mmcontacts__text__contacts__contact td-sp-b-1">
                <span v-html="icons.phone" class="sp-r-0-3"></span> {{ content.phone }}
              </a>
              <a :href="`mailto:${content.email}`" class="mmcontacts__text__contacts__contact sp-l-1 td-sp-l-none">
                <span v-html="icons.emailIcon" class="sp-r-0-3"></span> {{ content.email }}
              </a>
            </div>

            <div v-if="file" @click="downloadFile" class="mmcontacts__text__download sp-h-1 sp-v-1">
              <div class="mmcontacts__text__download__icon">
                <span v-html="icons.download" class="sp-r-0-3"></span>
              </div>
              <div class="mmcontacts__text__download__text">
                <h4 class="sp-b-0-3">{{ content.btntext }}</h4>
                <p class="font-t-m">{{ file.url.split('.')[1].toUpperCase() }} {{ file.size }}Мб</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import icons from '~/assets/icons'

const config = useRuntimeConfig()

const props = defineProps({
  data: Object
})

const content = props.data


const title = content.title
const image = config.public.strapiUrl + content.image.data.attributes.url
const cards = handleStrapiArray(content.cards).map(card => {
  return {
    ...card,
    icon: config.public.strapiUrl + card.icon.data.attributes.image.data.attributes.url
  }
})
const file = content.file.data?.attributes
const fileUrl = config.public.strapiUrl + file?.url

const downloadFile = async () => {
  let fileData = await fetch(fileUrl)
  fileData = await fileData.blob()
  const url = URL.createObjectURL(new Blob([fileData], { type: file.mime }))
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.href = url
  a.download = file.name
  a.click()
}

</script>

<style lang="stylus">
.mmcontacts
  h2
    color b2c-dark-blue

.mmcontacts__content
  @media (max-width: mobile-upper-limit)
    flex-direction column

.mmcontacts__image
  img
    width 100%

.mmcontacts__text
  width 85.7%
  color main-color05

  @media (max-width: tablet-upper-limit)
    width 100%

  &__description
    color main-color
    white-space: pre-wrap;

  &__cards__card
    display flex

    .card__text
      h4
        color b2c-dark-blue
        line-height 24px

    .card__icon
      img
        width 24px
        height 24px

  &__contacts
    display flex

    @media (max-width: tablet-upper-limit)
      flex-direction column

    &__contact
      display flex
      color main-color
      line-height 24px

      @media (hover: hover)
        &:hover
          cursor pointer
          color main-color03

      span
        display flex
        justify-content center
        align-items center

  &__download
    display flex
    background-color rgba(16, 24, 40, 0.03)
    border-radius 16px
    width 346px
    cursor pointer

    @media (hover: hover)
      &:hover
        background-color rgba(16, 24, 40, 0.05)

    @media (max-width: tablet-upper-limit)
      width 100%

    &__icon
      span
        display block
        height 24px

    h4
      color b2c-dark-blue
      font-size 16px
      line-height 24px

    p
      color main-color07
</style>
