<template>
  <div class="form">
    <div class="form__wrapper">
      <div class="title sp-r-4 md-sp-r-none">
        <div class="form__back sp-b-1-2" @click="renderPreviousStep" v-if="step === 2">
          <svg class="d-flex" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.47115 11.4692L13.9711 5.99976L15.0289 7.06337L10.0636 12.001L15.0289 16.9387L13.9711 18.0023L8.47115 12.5328C8.32959 12.392 8.25 12.2007 8.25 12.001C8.25 11.8014 8.32959 11.61 8.47115 11.4692Z" fill="#343B48"/>
          </svg>
          <p class="font-t-l">Назад</p>
        </div>
        <h2 class="font-h2 sp-b-1-2 color-main">Форма обратной связи</h2>
      </div>
      <FormFirstStep @changeStep="renderNextStep"
                     @changeStepsCount="setStepsCount"
                     v-if="step === 1"
                     :chosen-item="chosenItem"
                     :radio-items="radioItems"
                     class="sp-r-4 md-sp-r-none grid-form"/>
      <FormSecondStep v-if="step === 2" :form-type="chosenItem" class="sp-r-4 md-sp-r-none grid-form"
                      @changeStep="renderPreviousStep" :form-content="localFormContent" @popup="triggerPopup" @thirdStep="renderNextStep"/>
      <FormThirdStep v-if="step === 3" :link="localFormContent.work.url" :link-title="localFormContent.work.linkTitle"/>
      <FormPopup :show="showPopup" :success="orderSuccess" @close="hidePopup"/>
      <div class="form__right sp-l-2 md-sp-l-none md-sp-b-1">
        <FormSteps :step="step" :steps-count="stepsCount"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      step: 1,
      stepsCount: 2,
      chosenItem: 'connect',
      radioItems: [],
      fullData: [],
      formContent: {},
      localFormContent: {},
      orderSuccess: false,
      showPopup: false
    }
  },
  mounted() {
    this.fullData = toRaw(this.data).tipy_forms.data
    this.radioItems = this.fullData.map(item => {
      return {title: item.attributes.reason, value: item.attributes.siteFormTitle}
    })
    this.fullData.forEach(item => {
      this.formContent[item.attributes.siteFormTitle] = {
        reason: item.attributes.reason,
        formSubtitle: item.attributes.formSubtitle,
        url: item.attributes.url,
        linkTitle: item.attributes.linkTitle,
        description: item.attributes.description,
        formId: item.id,
        legal: item.attributes.legal.data.attributes.legalText,
        inputs: item.attributes.formFields.data.map(item => {
          return {
            placeholder: item.attributes.label,
            inputType: item.attributes.type,
            errorMessage: item.attributes.errorMessage,
            selectList: item.attributes.selectList?.data.map(item => {
              return {
                title: item.attributes.clientName,
                value: item.attributes.systemId
              }
            })
          }
        })
      }
    })
    this.localFormContent = toRaw(this.formContent)
  },
  methods: {
    renderPreviousStep() {
      this.step -= 1
    },
    renderNextStep($event) {
      this.chosenItem = $event
      this.step += 1
    },
    setStepsCount($event) {
      this.stepsCount = $event
    },
    triggerPopup($event) {
      this.showPopup = true
      this.orderSuccess = $event
    },
    hidePopup() {
      this.showPopup = false
    }
  }
}
</script>

<style lang="stylus">
.form
  width 100%
  background-color #fff
  color main-color
  &__wrapper
    width 980px
    padding 64px 56px
    border-radius 20px
    background-color main-color003
    margin 0 auto 100px
    display grid
    grid-template-areas 'title steps' 'form steps'
    position relative
    @media (max-width tablet-upper-limit)
      width 100%
      border-radius 0
      padding 40px
    @media (max-width mobile-upper-limit)
      display flex
      flex-direction column
      padding 40px 20px
      margin 0
  &__back
    display flex
    align-items center
    justify-content flex-start
    cursor pointer
    color main-color085
    transition color .2s linear
    @media (max-width mobile-upper-limit)
      display none
    svg path
      transition fill .2s linear
    @media(hover: hover)
      &:hover
        color main-color05
        svg path
          fill main-color05
  &__left
    flex 0 0 556px
    border-right 2px solid main-color003
    @media (max-width tablet-upper-limit)
      flex 1 0 368px
  &__right
    max-width 320px
    grid-area steps
    @media (max-width mobile-upper-limit)
      order 2
      flex-basis auto
      max-width auto

.form__wrapper
  .title
    grid-area title
    border-right 2px solid main-color003
    @media (max-width mobile-upper-limit)
      order 1
      border none

.grid-form
  grid-area form
  border-right 2px solid main-color003
  @media (max-width mobile-upper-limit)
    order 3
    border none
</style>