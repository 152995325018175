export const splitArrayOnChunks = (array: any[], chunksAmount: number) => {
  return array.reduce((acc: any[], curr: any, index: number) => { 
    const chunkIndex = Math.floor(index/chunksAmount)
  
    if(!acc[chunkIndex]) {
      acc[chunkIndex] = []
    }
  
    acc[chunkIndex].push(curr)
  
    return acc
  }, [])
}
