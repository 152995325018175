<template>
  <div class="product-form">
    <div class="product-form__wrapper">
      <div class="font-h2 sp-b-1-2">{{ data.title }}</div>
      <div class="product-form__fields-wrapper">
        <FormInput v-for="item in data.formFields.data" :input-type="item.attributes.type"
                   :placeholder="item.attributes.label" :ref="item.attributes.type" :class="item.attributes.type"
                   @customChange="fillCompany" :error-text="item.attributes.errorMessage"/>
      </div>
      <div class="sp-t-2 sp-b-1-3">
        <label class="checkbox">
          <input type="checkbox" class="checkbox__hidden" id="agreement" checked ref="checkbox" @change="setAgreement"/>
          <div class="checkbox__pseudo">
            <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="checkbox__checkmark">
              <path d="M10.3246 0.262523C10.7319 -0.110471 11.3645 -0.0826604 11.7375 0.32464C12.0818 0.70061 12.0846 1.26851 11.7626 1.64703L11.6754 1.73749L4.46522 8.34035L0.343636 4.75444C-0.073025 4.39193 -0.116925 3.76029 0.245584 3.34363C0.580206 2.95902 1.14415 2.89203 1.55665 3.16912L1.65639 3.24557L4.43002 5.65901L10.3246 0.262523Z" fill="#101828"/>
            </svg>
          </div>
          <div class="sp-l-0-3">
            <div class="radio__label" v-html="legal"/>
          </div>
        </label>
      </div>
      <button class="form__button form__button--md-full-width" @click="submitForm">Отправить</button>
    </div>
    <FormPopup :show="showPopup" :success="orderSuccess" @close="hidePopup"/>
  </div>
</template>

<script>
import { sendOrder } from '~/api/index'
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      showPopup: false,
      orderSuccess: false,
      chosenCompany: {},
      legal: ''
    }
  },
  mounted() {
    const config = useRuntimeConfig()
    this.legal = this.data.legal.data.attributes.legalText.replace('<a href="', `<a target="_blank" href="${config.public.strapiUrl}`)
  },
  methods: {
    async submitForm() {
      if(this.validate()) {
        const formData = {}
        this.data.formFields.data.forEach(item => {
          formData[item.attributes.type] = this.$refs[item.attributes.type][0].$refs.input.value
        })
        const phone = '7' + formData.phone.replace('(', '').replace(')', '').replaceAll(' ', '')
        formData.clientFio = formData.name
        formData.clientPhoneNumber = phone
        formData.clientEmail = formData.email
        formData.serviceExternalId = this.data.product.data.attributes.systemId
        formData.companyName = this.chosenCompany.companyName
        formData.companyInn = this.chosenCompany.companyInn
        formData.message = formData.textarea
        delete formData.phone
        delete formData.name
        delete formData.email
        delete formData.company
        delete formData.textarea
        const response = await sendOrder(formData)
        if(response.status === 200) {
          this.triggerPopup(true)
        } else {
          this.triggerPopup(false, response.message)
        }
      }
    },
    fillCompany($event) {
      if($event.type == 'company') {
        this.chosenCompany.companyName = $event.value.value
        this.chosenCompany.companyInn = $event.value.inn
      }
    },
    triggerPopup($event, error = '') {
      this.clearForm()
      this.showPopup = true
      this.orderSuccess = $event
      this.sendAnalytics($event, error)
    },
    validate() {
      let errors = 0
      for(let key in this.$refs) {
        if(key != 'checkbox') {
          this.$refs[key][0].validate(false)
          if(this.$refs[key][0].hasError) errors++
        } else {
          if(!this.$refs[key].checked) errors++
        }
      }
      return errors === 0
    },
    sendAnalytics(type, error) {
      if(process.client) {
        if(!window.dataLayer) window.dataLayer = []
        const analytics = {
          "event":"b2b",
          "type": "button",
          "value": type ? "order_succes" : "order_error",
          "label": type ? this.data.product.data.attributes.systemId : error,
          "content": "form",
          "num": "NA",
          "url": this.$route.fullPath
        }
        window.dataLayer.push(analytics)
        ym(64709935, 'reachGoal', analytics.value, {'events': analytics})
      }
    },
    clearForm() {
      this.data.formFields.data.forEach(item => {
        if(this.$refs[item.attributes.type][0].$refs.input) this.$refs[item.attributes.type][0].$refs.input.value = ''
        if(item.attributes.type == 'phone' || item.attributes.type == 'company') {
          this.$refs[item.attributes.type][0].$refs.input.dispatchEvent(new Event('blur'))
        } else if(item.attributes.type == 'file') {
          this.$refs[item.attributes.type][0].clearFiles()
        }
        this.$refs[item.attributes.type][0].$refs.input?.dispatchEvent(new Event('input'))
      })
    },
    hidePopup() {
      this.showPopup = false
    }
  }
}
</script>

<style lang="stylus">
.product-form
  width 100%
  background-color #fff
  color main-color
  &__wrapper
    width 980px
    padding 64px 56px
    border-radius 20px
    background-color main-color003
    margin 0 auto 100px
    position relative
    @media (max-width tablet-upper-limit)
      width 100%
      border-radius 0
      padding 40px
    @media (max-width mobile-upper-limit)
      display flex
      flex-direction column
      padding 40px 20px
      margin 0
  &__fields-wrapper
    display grid
    grid-template-areas 'name company''email phone' 'textarea textarea'
    gap 20px 22px
    @media (max-width tablet-upper-limit)
      display flex
      flex-direction column
      gap 18px
  .name
    grid-area name
  .company
    grid-area company
  .email
    grid-area email
  .phone
    grid-area phone
  .textarea
    grid-area textarea
  .input+.input
    margin-top 0
</style>