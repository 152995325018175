<template>
  <div class="rt-container">
    <div class="rt-col">
      <div class="advantages sp-v-5 td-sp-v-3 md-sp-v-2">
        <h2 class="advantages-header sp-b-2 td-sp-b-1-1 md-sp-b-1 font-h2">{{ title }}</h2>

        <div v-if="!isTablet" class="advantages-cards row">
          <div v-for="(row, idx) of tileset" :key="idx" class="advantages-cards-row">
            <ProductAdvantageCard
              v-for="(card, index) of handleStrapiArray(row.cards)" :key="index"
              :card="card"
              :class="[ colCountMap[row.type] ]"
            />
          </div>
        </div>

        <div v-else class="advantages-cards">
          <ProductAdvantageCard
            v-for="(card, index) of cardsPreview" :key="index"
            :card="card"
          />
          <button v-if="cards.length > 3" @click="showMore" class="show-more font-h4">
            <div v-if="cardsPreview.length <= 3">
              <span v-html="icons.chevron"></span>
              <p class="sp-l-0-3">Показать все</p>
            </div>
            <div v-else>
              <span v-html="icons.chevron" style="transform: rotateZ(180deg);"></span>
              <p class="sp-l-0-3">Свернуть</p>
            </div>
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import icons from '~/assets/icons.ts'

export default {
  props: {
    data: Object
  },

  setup(props) {
    const isTablet = ref(false)
    const cardsPreview = ref([])
    const cards = ref([])

    onMounted(() => {
      isTablet.value = window.innerWidth < 1025
      window.addEventListener('resize', () => {
        isTablet.value = window.innerWidth < 1025
      })
    })

    const advantagesContent = toRaw(props.data)
    
    const title = advantagesContent.header
    const tileset = handleStrapiArray(advantagesContent.tileset)
    cards.value = tileset.map(row => {
      return handleStrapiArray(row.cards)
    }).flat()

    cardsPreview.value = cards.value.slice(0, 3)

    const colCountMap = {
      t_2: 'rt-col-6',
      t_3: 'rt-col-4',
      t_4: 'rt-col-3',
    }

    const showMore = () => {
      if (cardsPreview.value.length <= 3) cardsPreview.value = cards.value
      else cardsPreview.value = cards.value.slice(0, 3)
    }

    return {
      title,
      tileset,
      colCountMap,
      isTablet,
      cardsPreview,
      icons,
      cards,
    
      showMore
    }
  },
}

</script>

<style lang="stylus">
.advantages
  width 100%

  &-header
    color b2c-dark-blue

  &-cards

    &-row
      display flex
      width 100%

      &:last-child
        .advantage-card-wrapper
          padding-bottom 0

      @media (max-width: tablet-upper-limit)
        display block

.show-more
  background-color main-color003-no-opacity
  width 100%
  height 48px
  border-radius 12px
  display flex
  justify-content center
  align-items center
  color b2c-dark-blue

  div
    display flex
    justify-content center
    align-items center

    span
      display flex
      justify-content center
      align-items center
      width 24px
      height 24px

      svg
        path
          fill #273A64

</style>
