<template>
  <div class="input font-t-m"
    :class="{
      'input--has-error' : hasError,
      'textarea' : inputType == 'textarea',
      'file-input' : inputType == 'file',
      'select' : inputType == 'select',
      'phone' : inputType == 'phone',
      'company' : inputType == 'company'
  }">
    <div class="input__wrapper">
      <textarea class="input__body" @input="watchFill" ref="input" v-if="inputType == 'textarea'"
                @change="sendAnalytics" maxlength="200"/>

      <div class="select__inner" v-else-if="inputType == 'select'" ref="input" @click="toggleList">
        <svg class="select__arrow" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4691 15.534L5.99707 10.0609L7.05783 9.00037L11.9994 13.9429L16.9411 9.00037L18.0018 10.0609L12.5298 15.534C12.3892 15.6747 12.1984 15.7537 11.9994 15.7537C11.8005 15.7537 11.6097 15.6747 11.4691 15.534Z" fill="#101828" fill-opacity="0.5"/>
        </svg>
        <p class="select__value">{{chosenService?.title}}</p>
        <div class="select__list">
          <div class="select__list-inner">
            <div class="select__list-item" v-for="item in selectList" :data-value="item.value" :data-text="item.title" @click="pickService">{{ item.title }}</div>
          </div>
        </div>
      </div>

      <div class="file-input__wrapper" v-else-if="inputType == 'file'">
        <input type="file" class="input__body--hidden" ref="fileInput" @change="setChosenFile" multiple/>
        <div>
          <p class="font-t-xs color-main07 sp-r-0-3" v-html="placeholder"/>
          <div class="file-input__content">
            <div class="file-input__content-item" v-for="(item, index) in filesList">
              <span class="font-t-xs file-input__content-item-title">{{item[0]?.name || item.name}}</span>
              <div class="file-input__content-item-remove" @click="removeItem(index)">
                <svg viewBox="0 0 24 24" fill="#000" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M10.94 12L6.848 16.09l1.06 1.061L12 13.06l4.092 4.092 1.06-1.06L13.061 12l4.091-4.092-1.06-1.06L12 10.939 7.91 6.848l-1.06 1.06L10.938 12z" clip-rule="evenodd"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <button class="file-input__button font-t-s" @click="uploadFile" :disabled="this.filesList.length >= 5">Прикрепить файл</button>
      </div>

      <div class="phone__wrapper" v-else-if="inputType == 'phone'">
        <input class="input__body" ref="input" @input="handleInput" @focus="setPrefix" @blur="removePrefix" @change="validate"/>
        <span class="phone__prefix d-none" ref="prefix">+7</span>
        <p class="input__placeholder" ref="placeholder">{{ placeholder }}</p>
        <div class="input__border"/>
      </div>

      <div class="company__wrapper" v-else-if="inputType == 'company'">
        <input class="input__body" ref="input" @input="searchCompany" @blur="hideList" @change="handleCompanyChange"/>
        <p class="input__placeholder" ref="placeholder">{{ placeholder }}</p>
        <div class="input__border"/>
        <div class="company__list" v-if="showList">
          <div class="company__list-item" v-for="item in companiesList" @click="pickCompany" :data-name="item.value" :data-inn="item.data.inn">
            <p class="company__name font-t-s">{{ item.value }}</p>
            <p class="company__inn font-t-xs">{{ item.data.inn }}</p>
          </div>
        </div>
      </div>

      <input class="input__body" ref="input" @input="watchFill" @blur="validate" @change="sendAnalytics" v-else/>
      <p class="input__placeholder" ref="placeholder" v-if="inputType != 'phone' && inputType != 'company'">{{ placeholder }}</p>
      <div class="input__border" v-if="inputType != 'phone' && inputType != 'company'"/>
      <p class="input__error-text font-t-xs color-error" v-show="hasError">{{ errorText }}</p>
      <p class="input__letters-count color-main05" v-if="inputType == 'textarea'">{{ lettersCount }}/200</p>
    </div>
  </div>
</template>

<script>
import { getCompany } from '~/api'
export default {
  props: {
    inputType: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: 'Введите текст'
    },
    errorText: {
      type: String,
      default: 'Заполните поле корректно'
    },
    selectList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chosenService: {},
      filesList: [],
      companiesList: [],
      chosenCompany: {},
      showList: false,
      hasError: false,
      lettersCount: 0
    }
  },
  mounted() {},
  methods: {
    watchFill($event, changeVal = true) {
      $event.stopPropagation()
      const value = $event.target.value.trim()
      if($event.target.value.trim().length > 0) {
        this.$refs.placeholder.classList.add('input__placeholder--on-top')
      } else {
        this.$refs.placeholder.classList.remove('input__placeholder--on-top')
      }
      if(!changeVal) return
      if(this.inputType == 'textarea') this.countLetters($event)
      this.$emit('customChange', {type: this.inputType, value: value})
    },
    uploadFile() {
      this.$refs.fileInput.click()
    },
    toggleList() {
      this.$refs.input.classList.toggle('select__inner--active')
    },
    pickService($event) {
      this.chosenService = {
        title: $event.target.dataset.text,
        value: $event.target.dataset.value
      }
      this.$refs.placeholder.classList.add('input__placeholder--on-top')
      this.sendAnalytics()
      this.validate()
      this.$emit('customChange', {type: this.inputType, value: this.chosenService})
    },
    setChosenFile($event) {
      $event.stopPropagation()
      const value = Array.from($event.target.files)
      if(value.length === 1) {
        this.filesList.push(value)
      } else {
        this.filesList = this.filesList.concat(value)
        if(this.filesList.length > 5) {
          this.filesList.length = 5
        }
      }
      this.sendAnalytics()
      this.$emit('customChange', {type: this.inputType, value: this.filesList})
    },
    removeItem(index) {
      this.filesList.splice(index, 1)
    },
    handleInput($event) {
      $event.target.value = this.phoneMask($event.target.value)
      this.watchFill($event)
    },
    phoneMask (phone) {
      return phone.replace(/\D/g, '')
          .replace(/^(\d)/, '($1')
          .replace(/^(\(\d{3})(\d)/, '$1) $2')
          .replace(/(\d{3})(\d{1,4})/, '$1 $2')
          .replace(/(\d{4})\d+?$/, '$1');
    },
    setPrefix($event) {
      this.$refs.prefix.classList.remove('d-none')
    },
    removePrefix($event) {
      if(this.$refs.input.value.trim().length == 0) {
        this.$refs.prefix.classList.add('d-none')
      }
    },
    async searchCompany($event) {
      if($event.target.value.trim().length < 3) return
      const response = await getCompany($event.target.value.trim())
      if(response?.status === 200) {
        this.companiesList = response.data.suggestions
        if(this.companiesList.length > 0) this.showList = true
      }
      this.watchFill($event, false)
    },
    pickCompany($event) {
      const companyData = $event.currentTarget.dataset
      this.chosenCompany = {
        value: companyData.name,
        inn: companyData.inn
      }
      this.$refs.input.value = this.chosenCompany.value
      this.$refs.placeholder.classList.add('input__placeholder--on-top')
      this.$emit('customChange', {type: this.inputType, value: this.chosenCompany})
    },
    hideList($event) {
      const companyData = $event.currentTarget.value
      this.chosenCompany = {
        value: companyData,
        inn: '000000000000'
      }
      this.$refs.placeholder.classList.add('input__placeholder--on-top')
      this.$emit('customChange', {type: this.inputType, value: this.chosenCompany})
      setTimeout(() => {
        this.showList = false
        this.watchFill($event, false)
      },100)
    },
    clearSelect() {
      this.chosenService = {}
      this.$refs.placeholder.classList.remove('input__placeholder--on-top')
    },
    clearFiles() {
      this.filesList = []
    },
    validate(shouldSendAnalytics = true) {
      if(this.inputType == 'email') {
        // const regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        const regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+[a-zA-Z0-9]+@(([a-zA-Z0-9](?:[a-zA-Z0-9-_]{0,61}[a-zA-Z0-9.])*\.[a-zA-Z0-9]+$)|([а-яА-ЯЁё0-9](?:[а-яА-ЯЁё0-9-_]{0,61}[а-яА-ЯЁё0-9.])*(\.рф)+$))/;
        this.hasError = !regex.test(this.$refs.input.value)
      }
      if(this.inputType == 'name') {
        const regex = /^[^a-zA-Z0-9]+$/
        this.hasError = !regex.test(this.$refs.input.value)
      }
      if(this.inputType == 'phone') {
        this.hasError = this.$refs.input.value.length != 14
        if(shouldSendAnalytics) this.sendAnalytics()
      }
      if(this.inputType == 'company') {
        this.hasError = this.$refs.input.value.length == 0
      }
      if(this.inputType == 'select') {
        this.hasError = !this.chosenService.value
      }
    },
    sendAnalytics() {
      const analytics = {
        "event":"b2b",
        "type": "field",
        "value": "field_change",
        "label": this.inputType,
        "content": "form",
        "num": "NA",
        "url": this.$route.fullPath
      }
      if(!window.dataLayer) window.dataLayer = []
      window.dataLayer.push(analytics)
      ym(64709935, 'reachGoal', 'field_change', {'events': analytics})
    },
    countLetters($event) {
      this.lettersCount = $event.target.value.length
    },
    handleCompanyChange() {
      this.validate()
      this.sendAnalytics()
    }
  }
}
</script>

<style lang="stylus">
.input
  transition height .2s linear
  height 48px
  color main-color
  &__wrapper
    width 100%
    height 48px
    position relative
    //padding 20px 0 4px
    border-bottom 2px solid main-color01
    transition border-color .2s linear, margin-bottom .2s linear
    .textarea &
      height 86px
      padding-top 20px
  &+&
    margin-top 18px
  &--has-error
    border-color error-color
    height 72px
  &__body
    border none
    outline none
    width 100%
    background-color transparent
    padding 20px 0 4px
    color main-color
    .textarea &
      padding-top 0
      overflow auto
    &--hidden
      display none
  &__placeholder
    position absolute
    top 12px
    color main-color07
    transition all .2s linear
    pointer-events none
    .input__body:focus ~ &,
    &--on-top
      top 4px
      font-size 12px
      line-height 16px
      color b2c-slate
    .input--has-error &
      color error-color!important
  &__error-text
    opacity .8
    position absolute
    bottom -24px
  &__border
    background-color b2c-slate
    width 0
    height 2px
    position absolute
    bottom -2px
    transition width .2s linear
  &__letters-count
    position absolute
    right 0
    bottom -24px
    font-size 12px
    line-height 16px
    opacity 0
    transition opacity .2s linear

.input__body:focus
  & ~ .input__border
    width 100%
  & ~ .input__letters-count
    opacity 1

.textarea
  height 86px
  .input__body
    resize none
    height 100%

.select
  position relative
  padding 0
  cursor pointer
  &__inner
    height 100%
    padding 20px 0 4px
    &--active
      .select__arrow
        transform translateY(-50%) rotate(-180deg)
      .select__list
        max-height 250px
        box-shadow 0 5px 10px main-color01
  &__arrow
    position absolute
    top 50%
    transform translateY(-50%)
    right 0
    width 24px
    height 24px
    transition transform .2s linear
  &__list
    position absolute
    max-height 0
    left 0
    right 0
    bottom -2px
    transform translateY(100%)
    background-color main-color003-no-opacity
    color main-color07
    overflow hidden
    transition max-height .2s linear
    z-index 2
    display flex
    width 100%
    border-bottom-left-radius 4px
    border-bottom-right-radius 4px
    &-inner
      overflow auto
      flex-grow 1
    &-item
      padding 12px 8px
      @media(hover: hover)
        &:hover
          color main-color
      &+&
        border-top 1px solid main-color007

.file-input
  height auto
  padding 18px
  border-radius 4px
  border 2px dashed main-color01
  .input__wrapper
    height auto
  .input&
    margin-top 40px
  &__wrapper
    display flex
    flex-direction row
    align-items flex-start
    justify-content space-between
  &__button
    padding 8px 20px
    border-radius 4px
    border 2px solid b2c-slate
    white-space nowrap
    cursor pointer
    transition background-color .2s linear, border-color .2s linear
    &:disabled,
    &[disabled]
      opacity .5
      pointer-events none
    @media(hover: hover)
      &:hover
        background-color #E0EBEF
    &:active
      border-color #324E57
  &__content
    display flex
    align-items flex-start
    justify-content flex-start
    gap 4px
    flex-wrap wrap
    &-item
      display flex
      align-items center
      justify-content flex-start
      padding 4px
      background-color #fff
      &:first-child
        margin-top 12px
      &-remove
        width 16px
        height 16px
        margin-left 8px
        cursor pointer
        svg
          width 100%
          height 100%
      &-title
        max-width 100px
        overflow hidden
        text-overflow ellipsis
        word-break keep-all
  .input__border,
  .input__error-text,
  .input__placeholder
    display none
  .input__wrapper
    border none

.phone__wrapper,
.company__wrapper
  position relative
  .input__body
    padding-top 18px
  .input__border
    bottom -6px

.phone__wrapper
  .input__body
    padding-left 20px

.phone__prefix
  position absolute
  top 17px
  left 0

.phone,
.company
  padding-top 0

.company__list
  position absolute
  bottom -7px
  transform translateY(100%)
  background-color main-color003-no-opacity
  box-shadow 0 5px 10px main-color01
  z-index 2
  width 100%
  color main-color07
  max-height 250px
  overflow auto
  &-item
    padding 12px 8px
    cursor pointer
    @media(hover: hover)
      &:hover
        color main-color
    &+.company__list-item
      border-top 1px solid main-color01
</style>