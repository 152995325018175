import AboutBlock from '../components/commonUi/AboutBlock.vue'
import Suitable from '../components/product/SuitableBlock.vue'
import Advantages from '../components/product/AdvantagesBlock.vue'
import MainBanner from '~/components/main/Banner.vue'
import MainProducts from '~/components/main/Products.vue'
import Achievements from '~/components/main/Achievements.vue'
import MainIndustries from '~/components/main/IndustriesBlock.vue'
import SimpleBanner from '~/components/SimpleBanner.vue'
import TextField from '~/components/commonUi/TextField.vue'
import PopularPages from '~/components/main/PopularPages.vue'
import Divider from '~/components/commonUi/Divider.vue'
import TrustCompanies from '~/components/commonUi/TrustCompanies.vue'
import Map from '~/components/contact/Map.vue'
import News from '~/components/main/News.vue'
import MassMediaContacts from '~/components/contact/MassMediaContacts.vue'
import AboutCompanyCardsBlock from '~/components/commonUi/AboutCompanyCardsBlock.vue'
import AboutCompanyDocuments from '~/components/commonUi/AboutCompanyDocuments.vue'
import AllProductsPage from '~/components/product/AllProductsPage.vue'
import Form from '~/components/form/Wrapper.vue'
import ProductForm from "~/components/form/ProductForm.vue";

export const dynamicComponentsMap = {
  ComponentBlocksBlokOProdukte: AboutBlock,
  ComponentBlocksBlokKomuPodojdet: Suitable,
  ComponentBlocksDinaBlokPreimushhestva: Advantages,
  ComponentMainpageBlokOblozhkaNaGlavnoj: MainBanner,
  ComponentMainpageBlokDostizheniya: Achievements,
  ComponentMainpageBlokIndustrii: MainIndustries,
  ComponentBlocksOblozhkaStraniczy: SimpleBanner,
  ComponentBlocksTekstovoePole: TextField,
  ComponentMainpageBlokCzifrovyeProduktyIUslugi: MainProducts,
  ComponentBlocksBlokObIndustrii: AboutBlock,
  ComponentMainpagePopulyarnyeStraniczy: PopularPages,
  ComponentBlocksRazdelitelBlokov: Divider,
  ComponentBlocksBlokNamDoveryayut: TrustCompanies,
  ComponentBlocksBlokKontakty: Map,
  ComponentMainpageNovosti: News,
  ComponentBlocksBlokKontaktyDlyaSmi: MassMediaContacts,
  ComponentBlocksBlokOKompaniiSIkonkami: AboutCompanyCardsBlock,
  ComponentBlocksTabySDokumentami: AboutCompanyDocuments,
  ComponentBlocksBlokProduktyIUslugi: AllProductsPage,
  ComponentBlocksFormaOsnovnaya: Form,
  ComponentBlocksFormaProdukta: ProductForm
}
