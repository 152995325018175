<template>
  <div class="rt-container sp-v-5 td-sp-v-2 md-sp-v-1">
    <div class="trusted__wrapper rt-col">
      <h2
        class="trust__header font-h2 rt-col-8"
        :class="
          description ? ['sp-b-1', 'td-sp-b-1', 'md-sp-b-1'] : ['sp-b-3', 'td-sp-b-1-1', 'md-sp-b-1']
        "
      >
        {{ title }}
      </h2>
      <div v-if="description" class="row">
        <p class="trust__description font-t-l rt-col-9 rt-col-td-6 sp-b-3-1 td-sp-b-1-1" v-html="description"></p>
      </div>
      <div class="trusted__logos row td-sp-b-1 td-sp-h-1">
        <div
          v-for="(logo, idx) of logos" :key="idx"
          class="trusted__logos_logo sp-b-3 td-sp-b-none td-sp-r-2 md-sp-r-1"
        >
          <img class="logo_bw" :src="logo.image" alt="">
          <img class="logo_color" :src="logo.imagecolor" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  data: Object
})

const config = useRuntimeConfig()

const title = props.data.title
const description = props.data.description
const logos =  handleStrapiArray(props.data.logos).map(logo => {
  return {
    id: logo.id,
    name: logo.name,
    image: config.public.strapiUrl + logo.image.data.attributes.url,
    imagecolor: config.public.strapiUrl + logo.imagecolor.data.attributes.url
  }
})

</script>

<style lang="stylus" scoped>
.trust__header
  color  b2c-dark-blue
  padding-left 0

.trust__description
 color b2c-black085-no-opacity
 white-space: pre-wrap;

.trusted__logos
  justify-content flex-start

  &_logo
    display flex
    justify-content center
    align-items center
    position relative

    @media (min-width: laptop-lower-limit)
      width 365px
      padding-left 10px
      padding-right 10px

    .logo_bw
      opacity 1
      transition opacity .5s
    .logo_color
      position absolute
      opacity 0
      transition opacity .5s

    @media (hover: hover)
      &:hover
        .logo_bw
          opacity 0
          transition opacity .5s
        .logo_color
          opacity 1
          transition opacity .5s

  @media (max-width: tablet-upper-limit)
    flex-wrap nowrap
    overflow auto

    &_logo
      @media (max-width: tablet-upper-limit)
        .logo_bw
          height 52px
      img
        @media (max-width: mobile-upper-limit)
          height 36px
</style>
