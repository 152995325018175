<template>
<div class="popular-pages sp-v-5 td-sp-v-3 md-sp-v-2">
  <div class="rt-container">
    <div class="rt-col">
      <div class="row">
        <h2 class="sp-b-2 td-sp-b-0-2 rt-col-8 font-h2 color-dark-blue">{{ title }}</h2>
      </div>
      <div class="row">
        <!-- cards desktop -->
        <div class="rt-col td-d-none">
          <div v-for="tile of tileset" :key="tile.id" :class="`popular-pages__tile popular-pages__tile--type-${tile.tile_type}`">
            <div :class="`popular-pages__card-${i+1}`" v-for="(card, i) of tile.cards" :key="card.id" >
              <PopularPagesDesktopCardVue :card="card" />
            </div>
          </div>
        </div>
        <!-- cards tablet/mobile -->
        <div class="row d-none td-d-flex sp-l-0-3 sp-r-0-3 td-sp-l-1 td-sp-r-none">
          <template v-for="tile of tileset" :key="tile.id">
            <div v-for="(card, i) of tile.cards" :key="card.id" class="popular-pages__card__wrapper rt-col-td-3 rt-col-md-3 sp-t-0-3">
              <NuxtLink :to="card.link" :class="`popular-pages__card popular-pages__card-${i+1}`">
                <span class="popular-pages__card__title font font-h4" v-html="card.title"></span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1827 12.749L12.9711 16.9388L14.029 18.0022L19.5289 12.5307C19.6704 12.39 19.75 12.1986 19.75 11.999C19.75 11.7994 19.6704 11.6081 19.5289 11.4673L14.0289 5.99585L12.9711 7.05926L17.1826 11.249L4 11.249L4 12.749L17.1827 12.749Z" fill="#273A64"/>
                </svg>
              </NuxtLink>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script setup>
  import icons from '~/assets/icons'
  import PopularPagesDesktopCardVue from './PopularPagesDesktopCard'

  const props = defineProps({
    data: Object
  })
  const title = props.data.title
  const tileset = handleStrapiArray(props.data.tileset)
</script>
<style lang="stylus" scoped>
  .popular-pages
    background-image url(/assets/Rectangle_4924.png)
    background-color #F8F8F8
    background-position -833px -463px
    background-repeat no-repeat
    @media(max-width: tablet-upper-limit)
      background-image url(/assets/Rectangle_4924_small.png)
      background-position -196px -132px
    @media(max-width: mobile-upper-limit)
      background-position -325px -23px

    &__tile
      width 100%
      display grid
      grid-template-columns repeat(12, 1fr)
      row-gap 20px
      column-gap 20px
      padding-top 20px
      text-align center
      
      @media(min-width: laptop-lower-limit)
        &--type
          &-t_1_2_2
            grid-template-areas "c1 c1 c1 c1 c2 c2 c2 c2 c3 c3 c3 c3"\
                                "c1 c1 c1 c1 c4 c4 c4 c5 c5 c5 c5 c5"
            grid-template-rows 182px 182px
          &-t_1_2_1
            grid-template-areas "c1 c1 c1 c1 c2 c2 c2 c2 c3 c3 c3 c3"\
                                "c1 c1 c1 c1 c4 c4 c4 c4 c3 c3 c3 c3"
            grid-template-rows 182px 182px
          &-t_1_1
            grid-template-areas "c1 c1 c1 c1 c1 c1 c2 c2 c2 c2 c2 c2"
            grid-template-rows 182px
          &-t_1_1_1
            grid-template-areas "c1 c1 c1 c1 c2 c2 c2 c2 c3 c3 c3 c3"
            grid-template-rows 182px
          &-t_1_1_1_1
            grid-template-areas "c1 c1 c1 c2 c2 c2 c3 c3 c3 c4 c4 c4"
            grid-template-rows 182px

      @media(max-width: tablet-upper-limit)
        display flex
        flex-direction row
        flex-wrap wrap

    &__card
      &__wrapper
        flex-direction row
        justify-content space-between
        align-items center
        text-align center
        justify-content center
        padding-left 0
        padding-right 20px

        @media(max-width: tablet-upper-limit)
          flex-basis 50%
          text-align left
        @media(max-width: mobile-upper-limit)
          flex-basis 100%
      i
        height 20px

      display flex
      flex-direction row
      align-items center
      text-align center
      justify-content center
      background-color #fff
      transition all 0.35s ease-out
      box-shadow 0px 0px 8px rgba(28, 41, 61, 0.05), 0px 2px 4px rgba(28, 41, 61, 0.06)
      border-radius 20px
      width 100%
      height 100%
      padding 0 25px

      @media(max-width: tablet-upper-limit)
        justify-content space-between
        border-radius 12px
        text-align left
        align-items center
        height 48px
        color #273A64

      for i in (1..10)
        &-{i}
          grid-area unquote('c' + i)

</style>