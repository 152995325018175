<template>
  <div class="all-products">
    <div class="rt-container">
      <div class="rt-col">
        <div class="row sp-b-2 md-sp-b-1">
          <div class="rt-col-9 rt-col-td-6">
            <h2 class="font-h2 sp-b-1 color-dark-blue">{{ title }}</h2>
            <p class="font-t-l all-products__description" v-html="description"></p>
          </div>
        </div>
        <div class="row">
          <div class="rt-col-4 rt-col-td-3 sp-b-2 td-sp-b-1 md-sp-b-0-3" v-for="(card, idx) of cards" :key="idx">
            <NuxtLink class="all-products-card sp-l-2 sp-r-1 sp-v-2 td-sp-l-1-3 md-sp-h-0-2 md-sp-v-0-4" :to="card.link">
              <img v-if="card.image" :src="config.public.strapiUrl + card.image" class="all-products-card__image"/>
              <p class="all-products-card__title" v-html="card.title"></p>
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const config = useRuntimeConfig()

const props = defineProps({
  data: Object
})

// console.log(toRaw(props.data))
const content = props.data

let { title, description, cards } = content
cards = handleStrapiArray(cards).map(card => {
  return {
    id: card.id,
    title: card.title,
    link: card.link,
    image: card.image.data?.attributes.url
  }
})

// console.log(cards)

</script>

<style lang="stylus">
  .all-products-card
    display flex
    align-items flex-end
    justify-content flex-start
    position relative
    height 345px
    border-radius 20px
    color #fff
    overflow hidden
    z-index 1
    @media(hover: hover)
      &:hover
        .all-products-card__image
          transform scale(1.2)
        &:after
          opacity .5
    @media (max-width mobile-upper-limit)
      border-radius 16px
    &:after
      position absolute
      content ''
      top 0
      left 0
      right 0
      bottom 0
      background linear-gradient(357.96deg, #273A64 -34.29%, #273A64 -16.92%, rgba(39, 58, 100, 0) 86.38%)
      pointer-events none
      opacity .9
      transition opacity .35s linear
    @media (max-width tablet-upper-limit)
      height 260px
    @media (max-width mobile-upper-limit)
      height auto
      aspect-ratio 1 / 1
    &__image
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      width 100%
      height 100%
      object-fit cover
      pointer-events none
      transition transform 1s
    &__title
      z-index 2
      font-size 28px
      line-height 36px
      font-weight 700
      @media (max-width tablet-upper-limit)
        font-size 24px
        line-height 28px
      @media (max-width mobile-upper-limit)
        font-size 12px
        line-height 16px
        font-weight 500
  .all-products
    padding-bottom 20px
    &:first-child
      padding-top 100px
      @media (max-width tablet-upper-limit)
        padding-top 60px
      @media (max-width mobile-upper-limit)
        padding-top 40px
    &:last-child
      padding-bottom 60px
      @media (max-width tablet-upper-limit)
        padding-bottom 40px
      @media (max-width mobile-upper-limit)
        padding-bottom 20px

    .rt-col-td-3
      @media (max-width mobile-upper-limit)
        &:nth-child(even)
          padding-left 6px
        &:nth-child(odd)
          padding-right 6px
    &__description
      color main-color085-no-opacity
      white-space: pre-wrap;
      @media (max-width mobile-upper-limit)
        font-size 14px
        line-height 20px
</style>
