<template>
  <div class="popup" :class="{'popup--active': localShow}">
    <div class="popup__body sp-h-2 sp-v-2 md-sp-h-1">
      <div class="popup__close-icon" @click="closePopup">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9399 12.0005L4.99878 17.9416L6.05944 19.0023L12.0005 13.0612L17.9416 19.0023L19.0022 17.9416L13.0612 12.0005L19.0022 6.05944L17.9416 4.99878L12.0005 10.9399L6.05946 4.99878L4.9988 6.05944L10.9399 12.0005Z" fill="#101828"/>
        </svg>
      </div>
      <div class="popup__content" v-if="success">
        <div class="popup__title-wrapper">
          <div class="popup__icon d-flex md-sp-b-1">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.9999 36.6666C29.2047 36.6666 36.6666 29.2047 36.6666 19.9999C36.6666 10.7952 29.2047 3.33325 19.9999 3.33325C10.7952 3.33325 3.33325 10.7952 3.33325 19.9999C3.33325 29.2047 10.7952 36.6666 19.9999 36.6666Z" fill="#5BCF6A"/>
              <path d="M24.8051 15.3148C25.2576 14.8675 25.9605 14.9009 26.3749 15.3892C26.7575 15.84 26.7605 16.5209 26.4028 16.9748L26.3059 17.0832L18.2946 25L13.7151 20.7005C13.2521 20.2659 13.2033 19.5086 13.6061 19.009C13.9779 18.5478 14.6045 18.4675 15.0629 18.7997L15.1737 18.8914L18.2555 21.7851L24.8051 15.3148Z" fill="white"/>
            </svg>
          </div>
          <p class="font-h3 sp-l-1 md-sp-l-none">Спасибо, заявка принята</p>
        </div>
        <p class="font-t-l md-sp-t-1">В ближайшее время мы свяжемся с вами</p>
      </div>
      <div class="popup__content" v-else>
        <div class="popup__title-wrapper">
          <div class="popup__icon d-flex md-sp-b-1">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle opacity="0.7" cx="20.0002" cy="19.9999" r="16.6667" fill="#EB3728"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M18.232 20.0002L12.4482 25.7839L14.2181 27.5495L19.9998 21.7679L25.7816 27.5498L27.5515 25.7841L21.7676 20.0002L27.5515 14.2163L25.7816 12.4507L19.9998 18.2324L14.2181 12.4507L12.4482 14.2163L18.232 20.0002Z" fill="white"/>
            </svg>
          </div>
          <p class="font-h3 sp-l-1 md-sp-l-none">Ваша заявка не принята</p>
        </div>
        <p class="font-t-l md-sp-t-1">Возникли технические неполадки, попробуйте позже</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    success: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    show(newVal, oldVal) {
      this.localShow = newVal
    }
  },
  data() {
    return {
      localShow: false
    }
  },
  mounted() {
    this.localShow = this.show
  },
  methods: {
    closePopup() {
      document.documentElement.style.overflow = 'auto'
      document.body.style.overflow = 'auto'
      this.$emit('close')
    }
  }
}
</script>

<style lang="stylus">
.popup
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  display flex
  align-items center
  justify-content center
  z-index 1000
  opacity 0
  pointer-events none
  color main-color
  &--active
    background-color main-color03
    opacity 1
    pointer-events auto
  &__body
    background #fff
    border-radius 20px
    position relative
    width 600px
    @media (max-width mobile-upper-limit)
      width calc(100vw - 40px)
  &__close-icon
    display flex
    position absolute
    top 20px
    right 20px
    cursor pointer
    svg
      path
        transition fill .2s linear
    @media(hover: hover)
      &:hover
        svg
          path
            fill main-color05-no-opacity
  &__title-wrapper
    display flex
    align-items center
    justify-content flex-start
    @media (max-width mobile-upper-limit)
      flex-direction column
  &__content
    @media (max-width mobile-upper-limit)
      text-align center
      .font-t-l
        color main-color05
        font-size 14px
        line-height 20px
</style>