interface StrapiItem {
    id: number;
    attributes: Array<Object>
}

export const handleStrapiArray = (list: any) => {
    let result = []

    if (list?.data) {
        result = list.data.map((item: StrapiItem) => ({
            id: item.id,
            ...item.attributes
        }))
    }

    return result
}

export const handleStrapiItem = (item: StrapiItem) => {
    return {...item.attributes}
}