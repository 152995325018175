<!-- !!!!! Смотри макет https://www.figma.com/file/HvJ3d9q1B0XQRtW5o3PTbp/Контакт-центр?node-id=1518%3A29549&t=exIkLSYo6o3IyvSj-0 -->
<!-- !!!!! Смотри макет https://www.figma.com/file/HvJ3d9q1B0XQRtW5o3PTbp/Контакт-центр?node-id=1518%3A29549&t=exIkLSYo6o3IyvSj-0 -->
<!-- !!!!! Смотри макет https://www.figma.com/file/HvJ3d9q1B0XQRtW5o3PTbp/Контакт-центр?node-id=1518%3A29549&t=exIkLSYo6o3IyvSj-0 -->

<template>
  <div class="achievements">

    <div v-if="!isTablet" class="rt-container">
      <section class="rt-col sp-v-5">
        <div class="achivements-table">
    
          <div class="achivements-table-toprow">
            <!-- Верхние 5 ячеек. Большая слева и 4 маленькие справа -->
            <div class="achivements-title sp-t-1 sp-r-1">
              <!-- Большая слева -->
              <NuxtLink to="/about" class="link font-h4 sp-b-1 d-block">
                Подробнее о компании <span v-html="icons.arrowRight" class="sp-l-0-3"></span>
              </NuxtLink>
              <p class="title font-h2 sp-b-1">{{ title.title }}</p>
              <p class="description font-t-m sp-b-2-3">{{ title.description }}</p>
            </div>
            <div
              v-for="(item, idx) of topRowItems" :key="item.id"
              class="achivements-item"
              :class="idx % 2 === 0 ? 'g3' : 'g4'"
            >
              <!-- 4 маленькие справа -->
              <p class="title font-h2 sp-b-1">{{ item.title }}</p>
              <p class="description font-t-m">{{ item.description }}</p>
            </div>
          </div>
    
          <div class="achivements-table-bottomrows">
            <!-- Здесь все остальные, которые идут ниже. Разбиты на ряды по 3 -->
            <div
              v-for="(row, rowIndex) of restAchivementsRows" :key="rowIndex"
              class="achivements-items rt-col-12"
            >
              <!-- Это ряд из трех айтемов -->
              <div
                v-for="(item, idx) of row" :key="item.id"
                class="achivements-items-item"
                :class="idx === 0 ? 'rt-col-5' : idx === 1 ? 'rt-col-3' : 'rt-col-4'"
              >
                <!-- Отдельный айтем -->
                <p class="title font-h2 sp-b-1">{{ item.title }}</p>
                <p class="description font-t-m">{{ item.description }}</p>
              </div>
            </div>
          </div>
    
        </div>
      </section>
    </div>

    <div v-else>
      <div class="rt-container">
        <section class="rt-col td-sp-t-3 td-sp-b-1-1 md-sp-t-1 md-sp-b-2">
          <div>
            <!-- Большая слева -->
            <p class="title font-h2 td-sp-b-1-1 md-sp-b-1">{{ title.title }}</p>
            <p class="description font-t-m td-sp-b-1-1 md-sp-b-1">{{ title.description }}</p>
            <NuxtLink to="/about" class="link font-h4 d-block">
              Подробнее о компании <span v-html="icons.arrowRight" class="sp-l-0-3"></span>
            </NuxtLink>
          </div>
        </section>
      </div>

      <CommonUiDivider />

      <div class="rt-container td-sp-t-1-1 td-sp-b-1-4 md-sp-t-2 md-sp-b-1">
        <section class="rt-col">
          <div class="row">
            <div v-for="(item, idx) of tabletItems" :key="idx" class="rt-col-td-3 rt-col-md-3">
              <div class="td-sp-b-1-1 md-sp-b-1">
                <div class="font-h2 title td-sp-b-0-2">{{ item.title }}</div>
                <div class="font-t-m description">{{ item.description }}</div>
              </div>
            </div>
          </div>
        </section>
      </div>

    </div>

  </div>
</template>

<script setup>
import icons from '../../assets/icons'

const props = defineProps({
  data: Object
})

const isTablet = ref(false)

onMounted(() => {
  isTablet.value = window.innerWidth < 1025
  window.addEventListener('resize', () => {
    isTablet.value = window.innerWidth < 1025
  })
})


const content = handleStrapiArray(props.data.achivements)

const title = content[0]
const topRowItems = content.slice(1, 5)
const restAchivementsRows = splitArrayOnChunks(content.slice(5), 3)

const tabletItems = content.slice(1)

onMounted(() => {
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if(entry.isIntersecting) {
        document.querySelector('.achivements-table').classList.add('achivements-table--animated')
        observer.unobserve(document.querySelector('.achivements-table'))
      }
    })
  }, {threshold: 1})
  if(observer) {
    observer.observe(document.querySelector('.achivements-table'))
  }
})

</script>

<style lang="stylus">
.achivements-table
  width 100%

  &-toprow
    display grid
    grid-template-columns repeat(12, 1fr)
    grid-template-rows repeat(2, 1fr)

    .achivements-title
      grid-column 1 / 6
      grid-row 1 / 3
      position relative
      &:before,
      &:after
        position absolute
        content ''
        background-color main-color007-no-opacity
        transition height 1s linear, width 1s linear
      &:before
        top 0
        right -1px
        width 2px
        height 0
      &:after
        left 0
        bottom -1px
        width 0
        height 2px

    .achivements-item
      width 100%
      padding 20px 20px 0 20px
      position relative
      &:after
        position absolute
        content ''
        background-color main-color007-no-opacity
        left 0
        bottom -1px
        width 0
        height 2px
      &.g3:after
        transition width .6s linear 1s
      &.g4:after
        transition width .8s linear 1.6s
      &:nth-child(2)
        &:before
          position absolute
          content ''
          background-color main-color007-no-opacity
          top 0
          right -1px
          width 2px
          height 0
          transition height .5s linear
      &:nth-child(4)
        grid-row 2 / 3
        &:before
          position absolute
          content ''
          background-color main-color007-no-opacity
          top 0
          right -1px
          width 2px
          height 0
          transition height .5s linear .5s
      &:nth-child(5)
        grid-row 2 / 3

    .g3
      grid-column 6 / 9
      grid-row 1 / 2

    .g4
      grid-column 9 / 13
      grid-row 1 / 2
      
  &-bottomrows
    display flex
    flex-wrap wrap

    .achivements-items
      width 100%
      display flex
      padding 0
      position relative
      &:after
        position absolute
        content ''
        background-color main-color007-no-opacity
        left 0
        bottom -1px
        width 0
        height 2px
      &:last-child
        &:after
          display none
        .achivements-items-item
          padding-bottom 40px

      &-item
        padding 20px 0 20px 20px
        position relative
        &:before
          position absolute
          content ''
          background-color main-color007-no-opacity
          top 0
          right -1px
          width 2px
          height 0
          transition height .5s linear 1s
        &:first-child
          padding 20px 20px 20px 0

        &:nth-child(3)
          &:after
            display none

  &--animated
    .achivements-title
      &:before
        height 100%
      &:after
        width 100%
    .achivements-table-toprow
      .achivements-item
        &:after
          width 100%
        &:nth-child(2):before,
        &:nth-child(4):before
          height 100%
    .achivements-table-bottomrows
      .achivements-items-item:before
        height 100%

.link
  display flex
  justify-content flex-start
  align-items center
  color b2c-slate
  transition .2s

  @media (hover: hover)
    &:hover
      color #516F79
      span
        transform translateX(8px)
        svg
          path
            fill #516F79

  span
    display flex
    justify-content center
    align-items center
    transform translateX(0px)
    transition .2s

.title
  color b2c-dark-blue

.description
  color main-color085-no-opacity

</style>
