<template>

  <!-- Мобильная версия -->
  <div class="cards-row-td d-none td-d-flex rt-col">
    <div v-for="card of cards" :key="card.id" class="td-card td-sp-b-0-3">
      <div class="common-about-card">
        <div class="common-about-card-icon sp-v-2 td-sp-v-none td-sp-b-0-3">
          <img :src="config.public.strapiUrl + card.icon.data.attributes.image.data.attributes.url" alt="">
        </div>
        <div class="common-about-card-content sp-b-none">
          <h4 class="common-about-card-content-header sp-b-0-2 font-h4">{{ card.header }}</h4>
          <p class="common-about-card-content-description font-t-m td-d-none">{{ card.description }}</p>
        </div>
      </div>
    </div>
  </div>

  <!-- Десктопная версия -->
  <div v-for="(row, idx) of rows" :key="idx" class="cards-row rt-col-12 d-flex td-d-none">
    <div v-for="card of row" :key="card.id" class="common-about-card rt-col-4 sp-h-1">
      <div class="common-about-card-icon sp-v-2">
        <img :src="config.public.strapiUrl + card.icon.data.attributes.image.data.attributes.url" alt="">
      </div>
      <div class="common-about-card-content sp-b-2">
        <h4 class="common-about-card-content-header sp-b-0-2 font-h4">{{ card.header }}</h4>
        <p class="common-about-card-content-description font-t-m">{{ card.description }}</p>
      </div>
    </div>
  </div>

</template>

<script setup>
const props = defineProps({
  cards: Array
})

const config = useRuntimeConfig()

const rows = splitArrayOnChunks(props.cards, 3)

</script>

<style lang="stylus" scoped>

.cards-row
  border-bottom 2px solid #eeeff0

  &:nth-child(even)
    .common-about-card
      padding-bottom 20px

  &:nth-child(odd)
    .common-about-card
      padding-top 20px

  &:last-child
    border-bottom none

.common-about-card
  box-sizing border-box
  color b2c-dark-blue

  @media screen and (max-width tablet-upper-limit)
    border 2px solid #eeeff0
    border-radius 16px
    padding 20px
    height 100%

  &:nth-child(2)
    border-left 2px solid #eeeff0
    border-right 2px solid #eeeff0

    @media screen and (max-width tablet-upper-limit)
      border 2px solid #eeeff0

  &-icon
    display flex
    align-items center
    justify-content flex-end

    @media screen and (max-width tablet-upper-limit)
      justify-content flex-start
    
    img
      @media screen and (max-width tablet-upper-limit)
        width 40px
        height 40px

    span
      display: flex;
      height: 56px;
      width: 56px;
      justify-content: center;
      align-items: center;

      @media screen and (max-width tablet-upper-limit)
        height: 40px;
        width: 40px;

  &-content-header
    width 100%
    margin 0
    @media screen and (max-width mobile-upper-limit)
      font-size 14px
      line-height 16px

  &-content-description
    width 100%
    margin 0




@media screen and (max-width tablet-upper-limit)
  .cards-row-td
    flex-wrap wrap

    .td-card
      width 50%

      &:nth-child(odd)
        padding-right 10px
      &:nth-child(even)
        padding-left 10px




@media screen and (max-width mobile-upper-limit)
  .cards-row-td
    flex-wrap wrap

    .td-card
      width 50%
      &:nth-child(odd)
        padding-right 6px
      &:nth-child(even)
        padding-left 6px
</style>
