<template>
  <article class="rt-container">
    <div class="contacts-list__wrapper-container">
      <div class="contacts__list rt-col-51 sp-t-5 td-sp-t-3 md-sp-t-2">
        <h2 class="font-h2 sp-b-2 td-sp-b-1">{{ centralContacts.title }}</h2>
        <Tabs name="mapTabs">

          <template v-slot:navigation>
            <TabsNavItem name="central" @change="displayMainMarker" parent-name="mapTabs">
              <slot>Центральный офис</slot>
            </TabsNavItem>
            <TabsNavItem name="local" @change="displayAllMarkers" parent-name="mapTabs">
              <slot>В городах России</slot>
            </TabsNavItem>
          </template>

          <template v-slot:content>
            <TabsContentItem name="central" parent-name="mapTabs">
              <div v-if="isMobile && currentTab === 'central'" class="contacts__map-container md-sp-b-1-1 md-sp-t-0-1">
                <div id="contacts__map" ref="mapRef"></div>
              </div>
              <div class="contacts-list__content">
                <h2 class="font-h3 sp-b-1">{{ centralContacts.companyname }}</h2>
                <p class="font-t-l sp-b-1">{{ centralContacts.address }}</p>
                <a :href="`tel:${centralContacts.phone}`" class="font-t-m sp-b-1 icon-text">
                  <span v-html="icons.phone" class="sp-r-0-3"></span>{{ centralContacts.phone }}
                </a>
                <a :href="`mailto:${centralContacts.email}`" class="font-t-m sp-b-1 icon-text">
                  <span v-html="icons.emailIcon" class="sp-r-0-3"></span>{{ centralContacts.email }}
                </a>
                <a class="font-t-m icon-text" :href="centralContacts.yandexbiz" target="_blank">
                  <span v-html="icons.linkIcon" class="sp-r-0-3"></span>Перейти на Яндекс Бизнес
                </a>
                <p class="font-h3 sp-b-1 sp-t-1-4">{{ centralContacts.hrtitle }}</p>
                <p class="font-t-l sp-b-1">{{ centralContacts.hrdescription.replace('-', '&#8209;') }}</p>
                <a :href="`tel:${centralContacts.hrphone}`" class="font-t-m sp-b-1 icon-text">
                  <span v-html="icons.phone" class="sp-r-0-3"></span>{{ centralContacts.hrphone }}
                </a>
                <a :href="`mailto:${centralContacts.hremail}`" class="font-t-m sp-b-3 icon-text">
                  <span v-html="icons.emailIcon" class="sp-r-0-3"></span>{{ centralContacts.hremail }}
                </a>
              </div>
            </TabsContentItem>
            <TabsContentItem name="local" parent-name="mapTabs">
              <div v-if="isMobile && currentTab === 'local'" class="contacts__map-container md-sp-b-1-1">
                <div id="contacts__map" ref="mapRef"></div>
              </div>
              <div class="contacts-list__content">
                <div class="contacts-list__content__input">
                  <input
                    @input="filterCities"
                    @focus="onFocus"
                    @blur="onBlur"
                    v-model="inputValue"
                    class="search_input"
                    type="text"
                    ref="searchInput"
                  />
                  <span :class="placeholderClassList">Введите название города</span>
                  <span
                    v-if="inputValue.length"
                    @click="clearSearch"
                    class="contacts-list__content__input-icon"
                    style="cursor: pointer;"
                    v-html="icons.closeIcon"
                    icon="close"
                  ></span>
                  <span v-else class="contacts-list__content__input-icon" v-html="icons.searchIcon"></span>
                </div>

                <!-- DESKTOP -->
                <div v-if="filteredOfficesList.length > 0 && !isMobile" class="contacts-list__content__list md-d-none">
                  <div v-for="(city, index) of filteredOfficesList" :key="index" class="contacts-list__content__list__item">
                    <p @click="filterCitiesOnClick(city.city)" class="city-list__city font-h3 sp-b-0-3">{{ city.city }}</p>
                    <div v-for="(office, idx) of city.offices" :key="idx">
                      <p @click="displayOfficeByClick(office, office.id)" class="city-list__address font-t-m flex sp-b-0-3">
                        <span class="sp-r-0-4" v-html="icons.markerIcon"></span>{{ office.address }}
                      </p>
                      <a
                        :href="office.yaBiz"
                        target="_blank"
                        :class="[
                          'font-t-m',
                          'flex',
                          'city-list__yandexbiz',
                          city.offices.length > 1 && idx !== city.offices.length - 1 ? 'border_dotted sp-b-1' : 'border_solid sp-b-1-4',
                          index === filteredOfficesList.length - 1 && idx === city.offices.length - 1 ? 'no-border' : ''
                        ]"
                      >
                        <span class="sp-r-0-4" v-html="icons.linkIcon"></span> Перейти на Яндекс Бизнес
                      </a>
                    </div>
                  </div>
                  <div @click="resetList" v-if="filteredOfficesList.length < officesList.length" class="contacts-list__content__list__back sp-b-2">
                    <span v-html="icons.arrowRight" class="sp-r-0-1"></span>К списку городов
                  </div>
                </div>

                <!-- MOBILE -->
                <div v-if="filteredOfficesListMobile.length > 0 && filteredOfficesList.length > 0 && isMobile" class="contacts-list__content__list md-d-block">
                  <div v-for="(city, index) of filteredOfficesListMobile" :key="index" class="contacts-list__content__list__item">
                    <p @click="filterCitiesOnClick(city.city)" class="city-list__city font-h3 sp-b-0-3">{{ city.city }}</p>
                    <div v-for="(office, idx) of city.offices" :key="idx">
                      <p @click="displayOfficeByClick(office, office.id)" class="city-list__address font-t-m flex sp-b-0-3">
                        <span class="sp-r-0-4" v-html="icons.markerIcon"></span>{{ office.address }}
                      </p>
                      <a
                        :href="office.yaBiz"
                        target="_blank"
                        :class="[
                          'font-t-m',
                          'flex',
                          'city-list__yandexbiz',
                          city.offices.length > 1 && idx !== city.offices.length - 1 ? 'border_dotted sp-b-1' : 'border_solid sp-b-1-4 md-sp-b-1',
                          index === filteredOfficesListMobile.length - 1 && idx === city.offices.length - 1 ? 'no-border' : ''
                        ]"
                      >
                        <span class="sp-r-0-4" v-html="icons.linkIcon"></span> Перейти на Яндекс Бизнес
                      </a>
                    </div>
                  </div>
                  <div @click="showMore" class="mobile__show-more">
                    <span v-html="icons.plus"></span>
                    <p class="sp-l-0-3">Показать еще</p>
                  </div>
                </div>

                <div v-if="filteredOfficesList.length === 0" class="contacts-list__content__list">
                  <h3 class="font-h3 sp-b-1">В данном городе офиса нет</h3>
                  <p class="font-t-m">Возможно, он появится позже</p>
                </div>
              </div>
            </TabsContentItem>
          </template>

        </Tabs>

      </div>
      <div v-if="!isMobile" class="contacts__map-container rt-col-71">
        <div id="contacts__map" ref="mapRef"></div>
      </div>
    </div>
  </article>
</template>

<script setup>
import icons from '~/assets/icons'

const props = defineProps({
  data: Object,
  topBorder: { type: Boolean, default: false },
  bottomBorder: { type: Boolean, default: false },
})
const content = props.data

const centralContacts = props.data.contactscentral.data.attributes

const mapRef = ref(null)
const searchInput = ref(null)
const inputValue = ref('')
const filteredOfficesList = ref([])
const filteredOfficesListMobile = ref([])
const isMobile = ref(false)
const currentTab = ref('central')
const centralOfficeCoords = centralContacts.centralcoords.split(', ')
const placeholderClassList = ref(['contacts-list__content__input-placeholder'])

let map = null
let clusterer = null
let geoObjects = []
let geoObjectsQuery = ref(null)
let visibleGeoObjects = ref(null)
let allowMapZoomEvent = ref(false)

onMounted(async () => {
  isMobile.value = window.innerWidth < 768
  if(!mapRef.value.children.length) {
    await initMap()
    displayMainMarker()
  }

  window.addEventListener('resize', async () => {
    isMobile.value = window.innerWidth < 768
  })
})

const coords = content.othercontacts.data.map(contact => {
  return {
    cityCoords: contact.attributes.citycoords.split(', ').map(coord => Number(coord)),
    officeCoords: contact.attributes.officecoords.split(', ').map(coord => Number(coord)),
    city: contact.attributes.city,
    address: contact.attributes.address,
    yaBiz: contact.attributes.yandexbiz
  }
})

let officesList = new Set()
coords.forEach(coord => {
  officesList.add(coord.city)
})
officesList = Array.from(officesList).map(city => {
  const data = coords.filter(coord => coord.city === city)
  return {
    city: city,
    offices: data
  }
})
filteredOfficesList.value = [...officesList]

filteredOfficesListMobile.value = officesList.slice(0, 3)

const showMore = () => {
  const startIndex = filteredOfficesListMobile.value.length
  officesList.slice(startIndex, startIndex + 3).forEach(el => filteredOfficesListMobile.value.push(el))
}

const resetList = () => {
  filteredOfficesList.value = [...officesList]
  map.setBounds(clusterer.getBounds(), {
    checkZoomRange: true
  })
}

const filterCities = () => {
  filteredOfficesList.value = officesList
    .filter(item => item.city.toLowerCase().startsWith(inputValue.value.toLowerCase()))
  
  displayFilteredMarkers(false, null)
}

const filterCitiesOnClick = (city) => {
  displayFilteredMarkers(true, city)
}

const clearSearch = () => {
  inputValue.value = ''
  setTimeout(() => {
    searchInput.value.dispatchEvent(new Event('input'))
    onBlur()
  }, 0)
}

const initMap = async () => {
  await ymaps.ready()

  const { Map, Clusterer } = ymaps
  clusterer = new Clusterer({
    groupByCoordinates: false,
    gridSize: 128,
    hasHint: false,
    minClusterSize: 2,
    clusterIconColor: '#7B939B'
  })

  map = new Map(document.getElementById('contacts__map'), {
    center: centralOfficeCoords,
    zoom: 16,
    controls: [
      'zoomControl',
    ]
  }, {
    maxZoom: 17
  })

  map.events.add('boundschange', function() {
    if (allowMapZoomEvent.value) {
      filterOnZoom()
    }
  });
}

const displayMainMarker = async () => {
  if (isMobile.value) {
    currentTab.value = 'central'
    if (map) {
      map.destroy()
      await initMap()
    }
  }

  map.geoObjects.removeAll()
  const mainMarker = createMarker(centralOfficeCoords, content.address)
  mainMarker.events.add('click', () => {
    document.getElementById(`markermain`).classList.add('custom_marker_layout_clicked')
  })

  map.geoObjects.add(mainMarker)
  map.setZoom(17)
  map.setCenter(centralOfficeCoords)
}

const displayAllMarkers = async () => {
  if (isMobile.value) {
    currentTab.value = 'local'
    if (map) {
      map.destroy()
      await initMap()
    }
  }

  resetMap()

  coords.forEach(coord => {
    // Айдишник, сибираемый из координат офиса. Добавляется в объект маркера карты и в объект офиса.
    // По нему происходит выборка нужного офиса при клике на маркер или на офис в списке, чтобы окрасить его
    // согласно макету дизайнеров
    const id = (coord.officeCoords[0].toString() + coord.officeCoords[1].toString()).replaceAll('.', '')
    const marker = createMarker(coord.officeCoords, coord.address, id)
    marker.id = id
    coord.id = id
    geoObjects.push(marker)
  })

  clusterer.add(geoObjects)
  map.geoObjects.add(clusterer)

  map.setBounds(clusterer.getBounds(), {
    checkZoomRange: true
  })

  setTimeout(() => {
    allowMapZoomEvent.value = true
  }, 100)
}

const filterOnZoom = () => {
  geoObjectsQuery = ymaps.geoQuery(geoObjects)
  visibleGeoObjects = geoObjectsQuery.searchIntersect(map)

  // const tmp = structuredClone(officesList)
  // из-за тима кука и его недобраузера сафари, мы не можем использовать structuredClone
  // воистину сафари - это новый осёл
  let tmp = JSON.stringify(officesList)
  tmp = JSON.parse(tmp)

  filteredOfficesList.value = tmp.filter(office => {
    office.offices = office.offices.map(item => {
      for (const obj of visibleGeoObjects._objects) {
        if (
          item.officeCoords[0] === obj.geometry._coordinates[0]
          && item.officeCoords[1] === obj.geometry._coordinates[1]
        ) {
          return item
        }
      }
    }).filter(Boolean)

    return office.offices.length > 0
  }).filter(Boolean)
}

const displayFilteredMarkers = (byClick, city) => {
  const { GeoObjectCollection } = ymaps

  let fileredCoords = byClick && city
    ? coords.filter(item => item.city === city)
    : toRaw(filteredOfficesList.value).map(item => {
    return item.offices
  }).flat()

  if (byClick && city) {
    map.setCenter(fileredCoords[0].cityCoords)
    map.setZoom(city.trim().toLowerCase() === 'москва' ? 10 : 12)
    return
  } else {
    if (filteredOfficesList.value.length > 0) {
      const collection = new GeoObjectCollection()
      fileredCoords.forEach((coord) => {
        const marker = createInvisibleMarker(coord.officeCoords)
        collection.add(marker)
      })
      map.geoObjects.add(collection)
      map.setBounds(collection.getBounds(), {
        checkZoomRange: true
      })
      map.geoObjects.remove(collection)
    }
  }
}

const displayOfficeByClick = (office, id) => {
  map.setZoom(17)
  map.setCenter(office.officeCoords)
  Array.from(document.querySelectorAll('.custom_marker_layout')).forEach(item => {
    item.classList.remove('custom_marker_layout_clicked')
  })
  setTimeout(() => {
    document.getElementById(`marker${id}`).classList.add('custom_marker_layout_clicked')
  }, 0)
}

const resetMap = () => {
  map.geoObjects.removeAll()
  clusterer.removeAll()
  geoObjects = []
}

const createMarker = (coords, hint, id = 'main') => {
  const { Placemark, templateLayoutFactory } = ymaps
  const iconCustomLayout = templateLayoutFactory.createClass(`
    <div class="custom_marker_layout_container">
      <div class="custom_marker_layout" id="marker${id}">
        ${icons.mapMarkerBordered}
      </div>
    </div>
  `)

  const marker = new Placemark(coords, {
    hintContent: hint
  }, {
    iconLayout: iconCustomLayout,
    iconShape: {
      type: 'Rectangle',
      coordinates: [ [ -20, -55 ], [ 20, 0 ] ]
    }
  })

  marker.events.add('mouseenter', () => {
    document.getElementById(`marker${id}`).classList.add('custom_marker_layout__hover')
  })
  marker.events.add('mouseleave', () => {
    document.getElementById(`marker${id}`).classList.remove('custom_marker_layout__hover')
  })
  marker.events.add('click', () => {
    map.setCenter(coords)
    map.setZoom(17)
    Array.from(document.querySelectorAll('.custom_marker_layout')).forEach(item => {
      item.classList.remove('custom_marker_layout_clicked')
    })
    document.getElementById(`marker${id}`).classList.add('custom_marker_layout_clicked')
  })

  return marker
}

// для центрирования при выборе города
const createInvisibleMarker = (coords) => {
  const { Placemark, templateLayoutFactory } = ymaps
  const iconCustomLayout = templateLayoutFactory.createClass(`
    <div style="display:none;">
      <div>
        ${icons.mapMarker}
      </div>
    </div>
  `)

  const marker = new Placemark(coords, {
    hintContent: ''
  }, {
    iconLayout: iconCustomLayout,
    iconShape: {
      type: 'Rectangle',
      coordinates: [ [ -20, -55 ], [ 20, 0 ] ]
    }
  })
  return marker
}

const onFocus = () => {
  if (!placeholderClassList.value.includes('contacts-list__content__input-placeholder__active')) {
    placeholderClassList.value.push('contacts-list__content__input-placeholder__active')
  }
}

const onBlur = () => {
  if (!inputValue.value.length) {
    placeholderClassList.value.splice(placeholderClassList.value.findIndex(el => el === 'contacts-list__content__input-placeholder__active'), 1)
  }
}

</script>

<style lang="stylus">
.icon
  position relative
  width 40px
  height 55px
  background no-repeat center center
  background-size contain

.border
  border-bottom 2px solid #EEEFF0

.contacts-list__wrapper-container
  display flex
  overflow hidden
  height 848px

  @media (max-width: tablet-upper-limit)
    overflow auto
    height 788px
    position relative

  @media (max-width: mobile-upper-limit)
    flex-direction column-reverse
    height auto
    padding 0 10px

.contacts__list
  color b2c-dark-blue
  width calc(8.33% * 5 + 10px)
  padding-left 10px
  height 848px

  @media (max-width: tablet-upper-limit)
    width 50%
    height 788px

  @media (max-width: mobile-upper-limit)
    width 100%
    padding-left 0
    height auto

.contacts__map-container
  width calc(8.33% * 7)

  @media (max-width: tablet-upper-limit)
    width 50%
  @media (max-width: mobile-upper-limit)
    width 100%

#contacts__map
  position absolute
  left calc(8.33% * 5 + 26px)
  right 1px
  height 848px
  overflow hidden

  @media (max-width: tablet-upper-limit)
    left 50%
    min-height auto
    height 320px
    top 184px
  
  @media (max-width: mobile-upper-limit)
    position relative
    width 100%
    left 0
    top 0

.icon-text
  display flex
  justify-content flex-start
  align-items center
  color main-color
  cursor pointer

  @media (hover: hover)
    &:hover
      color main-color03

  span
    display flex
    justify-content flex-start
    align-items center

.contacts-list__content
  width calc(80% - 20px)
  height 576px

  @media (max-width: tablet-upper-limit)
    width calc(100% - 20px)

  @media (max-width: mobile-upper-limit)
    width 100%
    height auto

  &__input
    position relative
    height 48px
    margin-bottom 40px
    border-bottom 2px solid main-color01
    
    .search_input
      position relative
      top 18px
      font-size 16px
      line-height 24px
      color main-color07
      border none
      width 100%
      outline none
      z-index 2
      background transparent
    
    &-icon
      position absolute
      top 12px
      right 0
      z-index 2

      @media (hover: hover)
        &[icon="close"]
          &:hover
            svg
              path
                fill #868B95

    &-placeholder
      position absolute
      top 12px
      left 0
      color main-color07
      transition top .2s, font-size .2s
      z-index 1

      &__active
        font-size 12px
        top 4px

  &__list
    height calc(100% - 88px)
    overflow auto

    @media (max-width: mobile-upper-limit)
      height auto

    &__item
      a
        color inherit
        width calc(100% - 40px)
      span
        display flex
        justify-content center
        align-items center

    &__back
      display flex
      justify-content flex-start
      align-items center
      cursor pointer
      span
        display flex
        justify-content center
        align-items center
        width 24px
        svg
          transform rotateZ(180deg)
      @media (hover: hover)
        &:hover
          color main-color03

.city-list__city
  cursor pointer
.city-list__address
  cursor pointer
  align-items flex-start!important
  span
    margin-top 1px

  @media (hover: hover)
    &:hover
      color #868B95

.city-list__yandexbiz
  cursor pointer

  @media (hover: hover)
    &:hover
      color #868B95

.border_solid
  border-bottom 2px solid #eeeff0
  margin-bottom 32px
.border_dotted
  border-bottom 2px dashed #eeeff0
  margin-bottom 20px
.no-border
  border none
  margin 0
.flex
  display flex
  justify-content flex-start
  align-items center

.custom_marker_layout_container
  position relative
.custom_marker_layout
  position absolute
  left -20px
  top -55px
  width 40px
  height 55px
.custom_marker_layout_clicked
  svg
    path#core
      fill #324E57
.custom_marker_layout__hover
  svg
    path#core
      fill #516F79

.mobile__show-more
  display flex
  justify-content center
  align-items center
  width 100%
  height 48px
  background-color #F8F8F8
  border-radius 12px
  margin-bottom 48px
</style>
