<template>
  <div class="about-wrapper rt-container">
    <div class="rt-col">
      <section class="about sp-v-5 td-sp-t-3 td-sp-b-2-2 md-sp-t-2 md-sp-b-1-2" v-if="cards.length">
        <div class="about__header">
          <div class="row sp-b-2 td-sp-b-1-1 md-sp-b-2">
            <div class="rt-col-8 rt-col-td-6">
              <h2 class="about__title font-h2">{{ header }}</h2>
            </div>
            <div class="rt-col-4 rt-col-td-6 td-sp-t-1-1 md-sp-t-1 ta-r" v-if="url">
              <a :href="url" class="about__link" target="_blank">
                <span>Скачать презентацию</span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8 8.5H9.5V7V4.5H14.5V7V8.5H16H17.8787L12 14.3787L6.12132 8.5H8ZM8 4V5.5V7H6.5H4.91421C4.02331 7 3.57714 8.07714 4.20711 8.70711L11.2929 15.7929C11.6834 16.1834 12.3166 16.1834 12.7071 15.7929L19.7929 8.70711C20.4229 8.07714 19.9767 7 19.0858 7H17.5H16V5.5V4C16 3.44772 15.5523 3 15 3H9C8.44772 3 8 3.44772 8 4ZM2 19V16H3.5V19C3.5 19.2761 3.72386 19.5 4 19.5H20C20.2761 19.5 20.5 19.2761 20.5 19V16H22V19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19Z" fill="#273A64"/>
                </svg>
              </a>
            </div>
          </div>
          <div class="row">
            <p class="about__description rt-col-9 rt-col-md-3 font-t-l sp-b-2 td-sp-b-1-1 md-sp-b-1" v-html="description" v-if="description"/>
          </div>
        </div>
        <div class="about-cards-block">
          <div class="row">
            <CommonUiAboutCards :cards="cards" />
          </div>
        </div>
      </section>
      <section class="about about--with-achievements sp-v-5 td-sp-v-3 md-sp-v-2" v-else-if="achievements.length">
        <div class="row">
          <div class="rt-col-6 rt-col-td-6">
            <div class="about__header sp-r-1">
              <h2 class="about__title font-h2 sp-b-1 color-dark-blue">{{ header }}</h2>
              <p class="about__description font-t-l sp-b-1 td-sp-b-1-1 md-sp-b-1" v-html="description" v-if="description"/>
            </div>
          </div>
          <div class="rt-col-6 rt-col-td-6">
            <div class="about__achievements">
              <h2 class="about__title font-h2 sp-b-1 color-dark-blue td-sp-b-1-1 md-sp-b-none">{{ subheader }}</h2>
              <div class="row sp-t-1 about__achievements-wrapper">
                <div v-for="(item, index) in achievements" class="rt-col-percent-5 sp-b-1-1 td-sp-b-1 md-sp-b-none rt-col-md-3">
                  <h2 class="about__title font-h2 sp-b-1 color-dark-blue md-sp-t-1 md-sp-b-0-3">{{ item.title }}</h2>
                  <p class="font-t-m color-main085 sp-r-1" :class="index < achievements.length - 1 ? 'md-sp-b-1' : ''">{{ item.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  data: Object
})

const header = props.data.header
const description = props.data.description
const cards = handleStrapiArray(props.data.iconcard)
const subheader = props.data.subheader
const achievements = handleStrapiArray(props.data.achievements)
const url = props.data.url

</script>

<style lang="stylus" scoped>

.about
  width 100%

  &__description
    color #343B48
    white-space pre-wrap

  &__title
    color #273A64

  &__achievements
    position relative
    height 100%
    display flex
    flex-direction column
    &:before
      position absolute
      content ''
      width 2px
      top -20px
      left -20px
      bottom 0
      background-color main-color007-no-opacity
      @media (max-width tablet-upper-limit)
        display none
    & .rt-col-percent-5
      position relative
      &:nth-child(2n)
        &:before
          position absolute
          content ''
          width 2px
          top -20px
          left -10px
          bottom 0
          background-color main-color007-no-opacity
          @media (max-width mobile-upper-limit)
            display none
      .about__title
        position relative
        &:before
          position absolute
          content ''
          height 2px
          top -20px
          left -20px
          right 0
          background-color main-color007-no-opacity
          @media (max-width mobile-upper-limit)
            top 0
      @media (max-width tablet-upper-limit)
        &:nth-child(odd)
          .about__title:before
            left 0
      @media (max-width mobile-upper-limit)
        &:nth-child(even)
          .about__title:before
            left 0
    .rt-col-percent-5 .about__title
      border-bottom none
    &-wrapper
      flex-grow 1
  &__link
    display inline-flex
    align-items center
    justify-content center
    background-color main-color003
    gap 12px
    border-radius 8px
    padding 12px 24px
    color b2c-dark-blue
    @media (max-width mobile-upper-limit)
      display flex

.ta-r
  @media (min-width laptop-lower-limit)
    text-align right

</style>
