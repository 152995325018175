<template>
  <section class="industries-block sp-v-2 td-sp-v-3-1 md-sp-v-2">
    <div class="rt-container">
      <div class="rt-col">
        <div class="row color-dark-blue ai-fe">
          <div class="rt-col-9 rt-col-td-6">
            <div class="font-h2 industries-block__title">{{ header }}</div>
          </div>
          <div class="rt-col-3 td-d-none">
            <NuxtLink to="/industries" class="industries-block__link">
              <span class="sp-r-0-3 color-dark-blue">Все индустрии</span>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1827 12.749L12.9711 16.9388L14.029 18.0022L19.5289 12.5307C19.6704 12.39 19.75 12.1986 19.75 11.999C19.75 11.7994 19.6704 11.6081 19.5289 11.4673L14.0289 5.99585L12.9711 7.05926L17.1826 11.249L4 11.249L4 12.749L17.1827 12.749Z" fill="#273A64"/>
              </svg>
            </NuxtLink>
          </div>
        </div>
        <div class="row color-main085 sp-t-1 td-sp-t-1-1 md-sp-t-1">
          <div class="rt-col-9 rt-col-td-6">
            <p class="font-t-l industries-block__description" v-html="description"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="industries-block__tile sp-v-3 td-sp-v-1-1 md-sp-v-1">
      <div class="industries-block__tile-wrapper" :style="`width:${tilesWidth}`" ref="wrapper">
        <div v-for="cards of tile" :key="cards.id" :class="`tile tile--type-${cards.type}`">
          <IndustryTileItem :tile="cards" @hover="emitHover"/>
        </div>
      </div>
    </div>
    <div class="rt-container">
      <div class="rt-col d-none td-d-block">
        <NuxtLink to="/industries" class="industries-block__link color-block--main-color003-no-opacity">
          <span class="sp-r-0-3 color-dark-blue">Все индустрии</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1827 12.749L12.9711 16.9388L14.029 18.0022L19.5289 12.5307C19.6704 12.39 19.75 12.1986 19.75 11.999C19.75 11.7994 19.6704 11.6081 19.5289 11.4673L14.0289 5.99585L12.9711 7.05926L17.1826 11.249L4 11.249L4 12.749L17.1827 12.749Z" fill="#273A64"/>
          </svg>
        </NuxtLink>
      </div>
    </div>
  </section>
</template>

<script>
import IndustryTileItem from "~/components/main/IndustryTileItem.vue"
import debounce from "~/utils/debounce"

export default {
  components: {
    IndustryTileItem
  },
  props: {
    data: Object
  },
  data () {
    return {
      tilesWidth: 'auto',
      header: '',
      description: '',
      tile: []
    }
  },
  mounted(){
    this.content = toRaw(this.data.industrii)
    let dataKeys = ['header', 'description', 'tile']
    dataKeys.forEach(item => {
      this[item] = this.content[item]
    })
    let tempArr = handleStrapiArray(this.tile).map(item => item)
    this.tile = [...tempArr, ...tempArr]
    this.$nextTick(() => {
      this.setWidth()
      this.$nextTick(() => {
        this.$refs.wrapper.style.animation = 'marquee 25s linear infinite'
      })
    })
    window.addEventListener('resize', debounce(this.setWidth, 100))
  },
  methods: {
    setWidth() {
      this.tilesWidth = `${this.$refs.wrapper.scrollWidth}px`
    },
    emitHover($event) {
      if($event) {
        this.$refs.wrapper.style.animationPlayState = 'paused'
      } else {
        this.$refs.wrapper.style.animationPlayState = 'running'
      }
    }
  }
}
</script>

<style lang="stylus">
.industries-block
  width 100%
  overflow hidden
  &__title
    @media (max-width mobile-upper-limit)
      font-size 28px
      line-height 32px
  &__link
    display inline-flex
    align-items center
    justify-content flex-end
    color inherit
    padding 12px 24px
    border-radius 8px
    float right
    font-weight 500
    background-color main-color003-no-opacity
    &:active
      background-color main-color007-no-opacity
    @media(hover: hover)
      &:hover
        background-color main-color005-no-opacity
    @media (max-width tablet-upper-limit)
      display flex
      justify-content center
      width 100%
      text-align center
      border-radius 12px
  &__description
    white-space: pre-wrap;
    @media (max-width tablet-upper-limit)
      font-size 18px
      line-height 28px
    @media (max-width mobile-upper-limit)
      font-size 14px
      line-height 20px
  &__tile-wrapper
    display flex
    pointer-events none
    &--paused
      animation-play-state paused

.ai-fe
  align-items flex-end

@keyframes marquee
  0%
    transform translateX(0)
  100%
    transform translateX(-50%)

</style>