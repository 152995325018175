<template>
  <div class="inputs-wrapper">
    <p class="font-h-l sp-b-1-2" v-html="formContent[formType].formSubtitle"/>
    <div v-if="formType == 'service'">
      <p class="font-t-m sp-b-2">{{ formContent[formType].description }}</p>
      <a :href="formContent[formType].url" class="form__button" @click="sendAnalytics(true)">{{ formContent[formType].linkTitle }}</a>
    </div>
    <div v-else>
      <FormInput v-for="item in formContent[formType].inputs" :input-type="item.inputType" :select-list="item.selectList"
                 :placeholder="item.placeholder" @customChange="checkFill" :ref="item.inputType" :error-text="item.errorMessage"/>
      <div class="sp-t-2 sp-b-1-3">
        <label class="checkbox">
          <input type="checkbox" class="checkbox__hidden" id="agreement" checked ref="checkbox" @change="setAgreement"/>
          <div class="checkbox__pseudo">
            <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg" class="checkbox__checkmark">
              <path d="M10.3246 0.262523C10.7319 -0.110471 11.3645 -0.0826604 11.7375 0.32464C12.0818 0.70061 12.0846 1.26851 11.7626 1.64703L11.6754 1.73749L4.46522 8.34035L0.343636 4.75444C-0.073025 4.39193 -0.116925 3.76029 0.245584 3.34363C0.580206 2.95902 1.14415 2.89203 1.55665 3.16912L1.65639 3.24557L4.43002 5.65901L10.3246 0.262523Z" fill="#101828"/>
            </svg>
          </div>
          <div class="sp-l-0-3">
            <div class="radio__label" v-html="formContent[formType].legal"/>
          </div>
        </label>
      </div>
      <div class="button-wrapper">
        <button class="form__button form__button--transparent" v-show="isMobile" @click="stepBack">Назад</button>
        <button class="form__button" @click="submitForm">Отправить заявку</button>
      </div>
    </div>
  </div>
</template>

<script>
import { sendOrder, sendEmail } from '~/api/index'
export default {
  props: {
    formType: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: 'Заголовок'
    },
    formContent: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      hasErrors: false,
      isEmpty: true,
      agreement: true,
      isMobile: false,
    }
  },
  mounted() {
    const config = useRuntimeConfig()
    this.formContent[this.formType].legal = this.formContent[this.formType].legal.replace('<a href="', `<a target="_blank" href="${config.public.strapiUrl}`)
    if(process.client) {
      this.isMobile = window.innerWidth < 768
    }
  },
  methods: {
    async submitForm() {
      if(this.validate()) {
        const formData = {}
        this.formContent[this.formType].inputs.forEach(item => {
          formData[item.inputType] = item.value
        })
        const phone = '7' + formData.phone.replace('(', '').replace(')', '').replaceAll(' ', '')
        formData.clientFio = formData.name
        formData.clientPhoneNumber = phone
        formData.clientEmail = formData.email
        delete formData.phone
        delete formData.name
        delete formData.email
        if(this.formType == 'connect') {
          formData.serviceExternalId = formData.select.value
          formData.companyName = formData.company.value
          formData.companyInn = formData.company.inn
          delete formData.company
          delete formData.select
          const response = await sendOrder(formData)
          if(response.status === 200) {
            this.showPopup(true)
          } else {
            this.showPopup(false, response.message)
          }
        } else {
          formData.application_type = this.formContent[this.formType].reason
          formData.application_type_id = this.formContent[this.formType].formId
          if(formData.textarea) {
            formData.message = formData.textarea
            delete formData.textarea
          }
          if(formData.file) {
            formData.file.forEach(item => {
              formData.file[item.name] = item
            })
          }
          const response = await sendEmail(formData)
          if(response.status === 200) {
            if(this.formType == 'work') {
              this.sendAnalytics(true)
              this.renderThirdStep()
            } else {
              this.showPopup(true)
            }
          } else {
            this.showPopup(false, response.data.message)
          }
        }
        this.clearForm()
      }
    },
    checkFill(data) {
      if(data.type && data.value) {
        const index = this.formContent[this.formType].inputs.findIndex(item => item.inputType == data.type)
        this.formContent[this.formType].inputs[index].value = data.value
        this.isEmpty = !!this.formContent[this.formType].inputs.find(item => {
          return !item.value && item.inputType != 'file' && item.inputType != 'textarea'
        })
      }
    },
    setAgreement($event) {
      this.agreement = $event.target.checked
    },
    clearForm() {
      this.formContent[this.formType].inputs.forEach(item => {
        if(this.$refs[item.inputType][0].$refs.input) this.$refs[item.inputType][0].$refs.input.value = ''
        if(item.inputType == 'select') {
          this.$refs[item.inputType][0].$refs.input.querySelector('.select__value').innerText = ''
          this.$refs[item.inputType][0].clearSelect()
        } else if(item.inputType == 'phone' || item.inputType == 'company') {
          this.$refs[item.inputType][0].$refs.input.dispatchEvent(new Event('blur'))
        } else if(item.inputType == 'file') {
          this.$refs[item.inputType][0].clearFiles()
        }
        this.$refs[item.inputType][0].$refs.input?.dispatchEvent(new Event('input'))
      })
    },
    sendAnalytics(type, error) {
      if(process.client) {
        if(!window.dataLayer) window.dataLayer = []
        const analytics = {
          "event":"b2b",
          "type": "button",
          "value": !type ? "order_error" : this.formType == 'connect' ? "order_succes" : "order_succes_step_2",
          "label": !type ? error : this.formContent[this.formType].reason,
          "content": "form",
          "num": "NA",
          "url": this.$route.fullPath
        }
        if(this.formType == 'connect') {
          const targetInput = this.formContent[this.formType].inputs.filter(item => item.inputType == 'select')
          analytics.orderId = targetInput[0].value.value
          analytics.offerCategory = targetInput[0].value.title
        }
        window.dataLayer.push(analytics)
        ym(64709935, 'reachGoal', analytics.value, {'events': analytics})
      }
    },
    stepBack() {
      this.$emit('changeStep')
    },
    showPopup(type, error = '') {
      document.documentElement.style.overflow = 'hidden'
      document.body.style.overflow = 'hidden'
      this.$emit('popup', type)
      this.sendAnalytics(type, error)
    },
    renderThirdStep() {
      this.$emit('thirdStep', 'service')
    },
    validate() {
      let errors = 0
      for(let key in this.$refs) {
        if(key != 'checkbox') {
          this.$refs[key][0].validate(false)
          if(this.$refs[key][0].hasError) errors++
        } else {
          if(!this.$refs[key].checked) errors++
        }
      }
      return errors === 0
    }
  }
}
</script>

<style lang="stylus">
.checkbox
  display flex
  align-items flex-start
  justify-content flex-start
  @media(hover: hover)
    &:hover
      cursor pointer
      .checkbox__pseudo
        background-color #E0EBEF
        border-color b2c-slate
  &__hidden
    display none
  &__pseudo
    width 20px
    height 20px
    position relative
    border-radius 4px
    border 2px solid main-color03
    transition border-color .2s linear, background-color .2s linear
    flex-shrink 0
    .checkbox__hidden:checked+&
      border-color b2c-slate
  &__checkmark
    position absolute
    top 50%
    left 50%
    transform translateX(-50%) translateY(-50%)
    opacity 0
    transition opacity .2s linear
    .checkbox__hidden:checked+.checkbox__pseudo &
      opacity 1
  .radio__label
    margin-left 0

.button-wrapper
  display flex
  align-items center
  justify-content space-between

.form__button--transparent
  color main-color
  background-color transparent
  border 2px solid b2c-slate
  padding 10px 24px

.checkbox
  a
    transition color .2s linear
    display inline
    @media(hover: hover)
      &:hover
        color #516F79

.radio__label
  display inline-block
  a
    color b2c-slate
</style>