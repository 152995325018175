<template>
  <div class="simple-banner">
    <div class="rt-container">
      <div class="rt-col">
        <div class="row md-fd-c-r">
          <div class="rt-col-5 rt-col-td-3 rt-col-md-3 md-o-2">
            <div class="simple-banner__text">
              <h1 
                class="simple-banner__title color-dark-blue"
                :class="[
                  !description ? 'sp-b-1-2 md-sp-b-none' : ''
                ]"
              >
                {{ title }}
              </h1>
              <div v-show="description" class="simple-banner__description font-t-l color-main07-no-opacity" v-html="description"></div>
              <div class="sp-t-2 td-sp-t-1">
                <NuxtLink :to="link" class="simple-banner__link" v-if="link?.length > 0">
                  <span>{{ linkText }}</span>
                  <div class="d-flex sp-l-0-3 simple-banner__link-icon" v-html="icons.arrowRight"/>
                </NuxtLink>
              </div>
            </div>
          </div>
          <div class="rt-col-xd-1 xd-d-none"/>
          <div class="rt-col-xd-6 rt-col-7 rt-col-td-3 rt-col-md-3 md-o-1">
            <div class="simple-banner__image">
              <img :src="imageUrl" alt="banner" class="simple-banner__image-body"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import icons from "assets/icons";

const props = defineProps({
  data: Object
})

const config = useRuntimeConfig()

const title = props.data.title
const description = props.data.description
const imageUrl = config.public.strapiUrl + props.data.image.data?.attributes?.url ?? 'noimage'
const link = props.data.link
const linkText = props.data.linkText

</script>

<style lang="stylus">
.simple-banner
  border-bottom 2px solid main-color007-no-opacity
  height 600px
  box-sizing content-box
  overflow hidden
  __description
    white-space pre-wrap
  @media (max-width tablet-upper-limit)
    height 340px
  @media (max-width mobile-upper-limit)
    height auto
  &__text
    width 100%
    height 100%
    display flex
    align-items flex-start
    justify-content center
    flex-direction column
    @media (max-width mobile-upper-limit)
      height auto
      margin-bottom 40px
  &__title
    font-weight 700
    font-size 48px
    line-height 60px
    margin-bottom 20px
    @media (max-width desktop-upper-limit)
      font-size 40px
      line-height 52px
    @media (max-width tablet-upper-limit)
      font-size 24px
      line-height 32px
      margin-bottom 12px
      margin-top 20px
  &__image
    width calc((100vw - 20px - var(--scrollbarWidth)) / 2)
    height 600px
    overflow hidden
    object-position 70%
    @media (min-width desktop-lower-limit) and (max-width desktop-upper-limit)
      width calc((100vw - 70px * 2) / 12 * 7 + 60px)
    @media (max-width laptop-upper-limit)
      width calc((100vw - 30px * 2) / 12 * 7 + 20px)
    @media (max-width tablet-upper-limit)
      width calc((100vw - 20px) / 2)
      height 340px
    @media (max-width mobile-upper-limit)
      position relative
      width calc(100% + 40px)
      left -20px
      height 288px
    &-body
      width 100%
      height 100%
      object-fit cover
  &__link
    color b2c-slate
    display flex
    align-items center
    justify-content flex-start
    font-size 22px
    line-height 28px
    font-weight 500
    @media(hover: hover)
      &:hover
        color #516F79
        .simple-banner__link-icon
          transform translateX(8px)
          svg
            path
              fill #516F79
  &__link-icon
    transition .2s linear transform

.xd-d-none
  @media (max-width desktop-upper-limit)
    display none

@media (max-width mobile-upper-limit)
  .md-o-1
    order 1
  .md-o-2
    order 2
</style>
