<template>
  <div class="form__choice">
    <p class="font-h-l sp-b-0-2">Выберите причину обращения</p>
    <fieldset class="radio-fieldset">
      <label class="radio__item" v-for="(item, index) in radioItems">
        <input type="radio" :id="index" name="reason" @change="setActive" class="radio__hidden"
               :value="item.value" :checked="chosenItem == item.value"/>
        <div class="radio__pseudo"/>
        <p class="radio__label">{{ item.title }}</p>
      </label>
    </fieldset>
    <button class="form__button form__button--md-full-width" @click="moveNextStep">Далее</button>
  </div>
</template>

<script>
export default {
  props: {
    chosenItem: {
      type: String,
      default: 'connect'
    },
    radioItems: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      localChosenItem: null,
    }
  },
  mounted() {
    this.localChosenItem = this.chosenItem
  },
  methods: {
    setActive($event) {
      this.localChosenItem = $event.target.value
      if($event.target.value == 'work') {
        this.$emit('changeStepsCount', 3)
      } else {
        this.$emit('changeStepsCount', 2)
      }
    },
    moveNextStep() {
      if(!!this.localChosenItem) this.$emit('changeStep', this.localChosenItem)
      if(process.client) {
        if(!window.dataLayer) window.dataLayer = []
        const analytics = {
          "event":"b2b",
          "type": "button",
          "value": "order_succes_step_1",
          "label": this.localChosenItem,
          "content": "form",
          "num": "NA",
          "url": this.$route.fullPath
        }
        window.dataLayer.push(analytics)
        ym(64709935, 'reachGoal', 'order_succes_step_1', {'events': analytics})
      }
    }
  }
}
</script>

<style lang="stylus">
.radio
  &__item
    display flex
    align-items flex-start
    justify-content flex-start
    margin-top 20px
    cursor pointer
    @media(hover: hover)
      &:hover
        .radio__pseudo
          border-color b2c-slate
          background-color #E0EBEF
  &__pseudo
    width 20px
    height 20px
    border 2px solid main-color03
    border-radius 50%
    position relative
    transition border-color .2s linear
    .radio__hidden:checked+&
      border-color b2c-slate
    &:before
      position absolute
      content ''
      width 8px
      height 8px
      border-radius 50%
      top 50%
      left 50%
      transform translateX(-50%) translateY(-50%)
      background-color transparent
      transition background-color .2s linear
      .radio__hidden:checked+&
        background-color main-color
  &__hidden
    display none
  &__label
    margin-left 12px
    cursor pointer
  &-fieldset
    border none
    margin-bottom 40px

.form__button
  display inline-block
  background-color b2c-slate
  padding 12px 24px
  border-radius 8px
  color #fff
  cursor pointer
  transition background-color .2s linear
  @media(hover: hover)
    &:hover
      background-color #516F79
  &:active
    background-color #324E57
  &:disabled,
  &[disabled]
    opacity .5
    pointer-events none
  &--md-full-width
    @media (max-width mobile-upper-limit)
      width 100%
</style>