<template>
  <div class="rt-container sp-v-5 td-sp-v-3 md-sp-v-2">
    <div class="rt-col">
      <div class="row">
        <div class="company__text rt-col-9 rt-col-td-6">
          <h2 class="title font-h2 sp-b-1">{{ title }}</h2>
          <div class="description sp-b-2 td-sp-b-1 font-t-l" v-html="description"></div>
        </div>
      </div>
      <div class="company__cards">
        <div v-for="(card, index) of cards" :key="index" class="company__cards__card">
          <div class="company__cards__card__content">
            <div class="company__cards__card__content__icon sp-v-2 td-sp-v-1 md-sp-b-0-3">
              <img :src="card.icon" alt="">
            </div>
            <div class="company__cards__card__content__desc sp-b-2 td-sp-b-1">
              <h4 class="font-h4">{{ card.header }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const config = useRuntimeConfig()

const props = defineProps({
  data: Object
})

const title = props.data.title
const description = props.data.description
const cards = handleStrapiArray(props.data.cards).map(card => {
  return {
    ...card,
    icon: config.public.strapiUrl + card.icon.data.attributes.image.data.attributes.url
  }
})
</script>

<style lang="stylus">
.company__text
  .title
    color b2c-dark-blue
  .description
    color main-color085
    white-space: pre-wrap;

.company__cards
  display flex
  flex-wrap nowrap

  @media (max-width: tablet-upper-limit)
    flex-wrap wrap

  &__card
    width calc(25% + 10px)
    padding 0 20px
    border-top 2px solid #eeeff0
    border-bottom 2px solid #eeeff0
    border-left 2px solid #eeeff0

    @media (max-width: tablet-upper-limit)
      width calc(50% - 10px)!important
      padding 0
      border 2px solid #eeeff0
      border-radius 16px
      
      &:nth-child(odd)
        margin-right 20px
        @media (max-width: mobile-upper-limit)
          margin-right 12px
      &:nth-child(-n + 2)
        margin-bottom 20px
        @media (max-width: mobile-upper-limit)
          margin-bottom 12px

    @media (max-width: mobile-upper-limit)
      width calc(50% - 6px)!important

    &__content

      @media (max-width: tablet-upper-limit)
        padding 0 20px

      &__icon
        display flex
        justify-content flex-end

        @media (max-width: tablet-upper-limit)
          justify-content flex-start

        img
          @media (max-width: tablet-upper-limit)
            width 40px
            height 40px

      &__desc
        color b2c-dark-blue

        h4
          @media (max-width: mobile-upper-limit)
            font-size 14px
            line-height 16px

    &:first-child
      width calc(25% - 20px)
      padding-left 0
      border-left none

      @media (max-width: tablet-upper-limit)
        width 50%
        border 2px solid #eeeff0

      .company__cards__card__content
        &__desc
          padding-left 20px

          @media (max-width: tablet-upper-limit)
            padding 0
    &:last-child
      padding-right 0
</style>
